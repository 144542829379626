import { useEffect, useState } from 'react';
import { MessageResponse } from '../../../../services/AIx/assistantsTypes';

export const useEventSource = (
    assistantId: string,
    threadId: string,
    runRequest: number,
    messagesMap: { [id: string]: MessageResponse },
    setMessages: React.Dispatch<React.SetStateAction<MessageResponse[]>>,
    setLoadingStream: (boolean) => void,
    setLoading: (boolean) => void,
) => {
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 0; // Maximum number of retry attempts
    const retryDelay = 900; // Delay between retries in milliseconds
    const baseUrl = process.env.REACT_APP_AIX_LLM_API;
    const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
    const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
    const url = `${baseUrl}/api/threads/stream/${assistantId}/thread/${threadId}?Authorization=${LiveCloud_AuthMS}&AuthorizationPlatform=${LiveCloud_AuthLC}&Platform=1`;

    useEffect(() => {
        if (runRequest === 0) return;

        setRetryCount(0); // Reset retry count on successful connection
        let eventSource:EventSource | null = null;

        const connectToEventSource = () => {
            eventSource = new EventSource(url);

            const handleOpen = () => {
                setLoading(true);
                setLoadingStream(true);
                console.log('Connected to EventSource');
            };

            const handleMessage = (event) => {
                try {
                    // setLoading(true);
                    setLoadingStream(true);

                    if (event.data.includes("[DONE]")) {
                        return;
                    } else if (event.data.includes('Error connecting')) {
                        setLoading(false);
                        throw new Error(event.data);
                    }

                    const data = JSON.parse(event.data.replace('data: ', ''));
                    handleIncomingData(data);
                } catch (error) {
                    console.error('Error parsing message data:', error);
                } finally {
                    setLoading(false);
                }
            };

            const handleError = (error) => {
                console.error('EventSource failed. Attempting to reconnect...');
                if (retryCount < maxRetries) {
                    setTimeout(() => {
                        setRetryCount((prev) => prev + 1);
                        // setLoadingStream(true);
                        connectToEventSource(); // Retry the connection
                    }, retryDelay);
                } else {
                    console.error('Max retries reached. Stopping connection attempts.', error);
                    setLoadingStream(false);
                    eventSource?.close();
                }
            };

            const handleIncomingData = (data) => {
                if (data.object === 'thread.message') {
                    handleThreadMessage(data);
                } else if (data.object === 'thread.message.delta') {
                    handleThreadMessageDelta(data);
                }
            };

            const handleThreadMessage = (message) => {
                messagesMap[message.id] = message;
                setMessages(Object.values(messagesMap));
            };

            const handleThreadMessageDelta = (data) => {
                const { id: messageId, delta, created_at, assistant_id, thread_id, run_id, role } = data;

                if (!messagesMap[messageId]) {
                    messagesMap[messageId] = {
                        id: messageId,
                        object: 'message',
                        created_at,
                        assistant_id,
                        thread_id,
                        run_id,
                        role,
                        content: [],
                        attachments: [],
                        metadata: {}
                    };
                }

                const contentPart = delta.content[0]?.text?.value || '-';
                if (contentPart.includes('[DONE]')) setLoadingStream(false);

                messagesMap[messageId].content.push({
                    type: 'text',
                    text: {
                        value: contentPart,
                        annotations: delta.content[0]?.text?.annotations || []
                    }
                });

                setMessages((prevMessages: MessageResponse[]) => {
                    const existingMessageIndex = prevMessages.findIndex((msg) => msg.id === messageId);
                    const updatedMessages = [...prevMessages];

                    if (existingMessageIndex !== -1) {
                        updatedMessages[existingMessageIndex] = messagesMap[messageId];
                    } else {
                        updatedMessages.push(messagesMap[messageId]);
                    }

                    return updatedMessages;
                });
            };

            eventSource.onopen = handleOpen;
            eventSource.onmessage = handleMessage;
            eventSource.onerror = handleError;

            return () => {
                setLoading(false);
                eventSource?.close();
            };
        };

        connectToEventSource();

        return () => {
            setLoading(false);
            eventSource && eventSource.close();
        };

    }, [runRequest]);
};

