// Marketing/index.tsx
import React from 'react';
import Ads from './Ads';
import * as Solutions from './Ads/Solutions';

interface MarketingProps {
    selectedSolution: string | null;
    onSolutionClose: () => void;
}

const Marketing: React.FC<MarketingProps> = ({ selectedSolution, onSolutionClose }) => {
    const solutions = {
        'cloudmanagedservicescorporate': new Solutions.cloudmanagedservicescorporate(),
        'cloudmanagedservicesenterprise': new Solutions.cloudmanagedservicesenterprise(),
        'cloudmanagedservicespremier': new Solutions.cloudmanagedservicespremier(),
        'backupbasic': new Solutions.backupbasic(),
        'backuppremium': new Solutions.backuppremium(),
        'retencao': new Solutions.retencao(),
        'disasterrecovery': new Solutions.disasterrecovery(),
        'saasbasic': new Solutions.saasbasic(),
        'saaspremium': new Solutions.saaspremium(),
        'erp': new Solutions.erp(),
        'saasbackup': new Solutions.saasbackup(),
        'eaeas': new Solutions.eaeas(),
        'diime': new Solutions.diime(),
        'doone': new Solutions.doone(),
        'heyho': new Solutions.heyho(),
        'maxsoftware': new Solutions.maxsoftware(),
        'merito': new Solutions.merito(),
        'receeba': new Solutions.receeba(),
        'xdr': new Solutions.edr(),
        'firewall': new Solutions.firewall(),
        'identityaccess': new Solutions.identityaccess(),
        'livewatch': new Solutions.livewatch(),
        'patchmanager': new Solutions.patchmanager(),
        'secureendpoint': new Solutions.secureendpoint(),
        'maxoutsource': new Solutions.maxoutsource(),
        'finops': new Solutions.finops(),
        'nf': new Solutions.nf()
    };

    return (
        <div>
            {selectedSolution && (
                <Ads                    
                    visibilidade={`visible`}
                    onClose={onSolutionClose}
                    solucao={solutions[selectedSolution]}
                />
            )}
        </div>
    );
}

export default Marketing;