import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { LCTechService } from "../../../services/LCTech/LCTechService";
import LcIconLink from '../../../components/Generic/LcIconLink';
import { useSnackbar } from 'notistack';
import "./index.css";
import ReactHtmlParser from 'react-html-parser';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { PiDatabaseLight, PiDeviceTabletLight, PiFileTextLight, PiFunnelLight, PiHeadsetLight, PiIdentificationCardLight, PiSquaresFourLight } from 'react-icons/pi';

//Img Fluxo
import ImgFluxo from '../../../assets/images/Fluxos/csp.png';
import history from '../../../history';
import HeyHoNewRequest, { FormDataHeyho } from '../../../components/Generic/HeyHoNewRequest';
import FilterPanel from '../../../components/Data/FilterPanel';
import { SecureScore } from '../../../services/LCTech/LCTechModel';
import { cleanText } from '../../Heyho/HHContentAI';
import AixCompanySevice from '../../../services/AixCompanyService/AixCompanyService';
import ReactECharts from 'echarts-for-react';
import { getIconAndColor } from '../resume';
import { ProgressBarWithLabel } from '../../../components/Layout/Dashboard/ProgressBarWithLabel';


const defaultCategories = [
    { category: 'apps', count: 0, color: '#471fcc', icon: <PiSquaresFourLight size={32} /> },
    { category: 'data', count: 0, color: '#471fcc', icon: <PiDatabaseLight size={32} /> },
    { category: 'identity', count: 0, color: '#471fcc', icon: <PiIdentificationCardLight size={32} /> },
    { category: 'device', count: 0, color: '#471fcc', icon: <PiDeviceTabletLight size={32} /> },
];

const getColor = (category: string) => {
    const catData = defaultCategories.find(cat => cat.category == category);
    return catData ? catData.color : '#8684EF';
}

const getIcon = (category: string) => {
    const catData = defaultCategories.find(cat => cat.category == category);
    return catData ? catData.icon : 'lci lci-puzzle';
}

const mergeDataWithDefaults = (data) => {
    return defaultCategories.map(def => {
        const fromData = data.find(item => item.category == def.category);
        return fromData ? fromData : def;
    });
}

const CSPSecurityScore: React.FC = (props: any) => {
    const SecureDafeult: SecureScore = {
        currentScore: 0,
        maxScore: 0,
        licenseCount: 0,
        servicesCount: 0,
        servicesList: '',
        secureScoreCategoryList: [],
        averageComparativeScores: []
    }
    const AixCompany = new AixCompanySevice(props);
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const ServiceInstance = new LCTechService({});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [scorePercent, setScorePercent] = useState<number>(0);
    const [implementationStatus, setImplementationStatus] = useState<string>('');
    const [showImplementationStatus, setShowImplementationStatus] = useState<boolean>(false);
    const [modalVisibleHeyHoCall, setModalVisibleHeyHoCall] = useState<boolean>(false);
    const [secureScore, setSecureScore] = useState<SecureScore>(SecureDafeult);
    const [selectedCategoryDetailList, setSelectedCategoryDetailList] = useState<any[]>([]);
    const [filteredCategoryDetailList, setFilteredCategoryDetailList] = useState<any[]>([]);
    const [cards, setCards] = useState<Card[]>([]);
    const [cardBackgroundColors, setCardBackgroundColors] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "description", direction: "asc" });
    const [formData, setFormData] = useState<FormDataHeyho>({ title: '', description: '', gtmType: 0 });
    const { enqueueSnackbar } = useSnackbar();
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const infoAnchorRef = useRef<any>(null);
    const scrollToInfoAnchor = () => infoAnchorRef.current.scrollIntoView({ behavior: "smooth" });
    const [categoryItem, setCategoryItem] = useState<any>()
    const [itemDatail, setItemDatail] = useState<any>()
    const [atualPercentageApps, setatualPercentegeApps] = useState<number>(0)
    const [maxpercentageApps, setMaxPercentegeApps] = useState<number>(0)
    const [atualPercentageDevices, setAtualPercentegeDevices] = useState<number>(0)
    const [maxpercentageDevices, setMaxPercentegeDevices] = useState<number>(0)
    const [atualPercentageIdentity, setAtualPercentegeIdentity] = useState<number>(0)
    const [maxpercentageIdentity, setMaxPercentegeIdentity] = useState<number>(0)
    const [atualPercentageData, setAtualPercentegeData] = useState<number>(0)
    const [maxpercentageData, setMaxPercentegeData] = useState<number>(0)
    const [loadingSugggestion, setLoadingSugggestion] = useState<boolean>(false);
    const [openAiModal, setOpenAiModal] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [suggestion, setSuggestion] = useState<string[]>([]);
    const [rowAI, setRowAI] = useState<any>();
    const [fraudEventsReportCount, setFraudEventsReportCount] = useState<number>(0);
    const [fraudEventsReportCountLoading, setFraudEventsReportCountLoading] = useState<any>(true);

    type MergedDataItem = {
        scoreDetailList: any;
        category: string;
        count: number;
        color: string;
        icon: string;
    };

    const gaugeData = [
        {
            value: secureScore.averageComparativeScores.find(fil => fil.basis === "AllTenants")?.averageScore.toFixed(0) || 0,
            name: "Média de mercado",
            title: {
                offsetCenter: ["0%", "30%"],
                color: '#966CF3',
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ["0%", "45%"],
            },
            itemStyle: {
                color: '#966CF3',
                borderColor: '#e6ebf8', // Cor da borda
                borderWidth: 2, // Largura da borda
            },
        },
        {
            value: secureScore.averageComparativeScores.find(fil => fil.basis === "TotalSeats")?.averageScore.toFixed(0) || 0,
            name: "Empresas semelhantes",
            title: {
                offsetCenter: ["0%", "-5%"],
                color: '#471FCC',
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ["0%", "10%"],
            }, itemStyle: {
                color: '#471FCC',
                borderColor: '#e6ebf8',
                borderWidth: 2,
            },
        },
        {
            value: (secureScore.currentScore && secureScore.maxScore) ? ((secureScore.currentScore * 100) / secureScore.maxScore).toFixed(0) : 100,
            name: "Seu score",
            title: {
                offsetCenter: ["0%", "-40%"],
                color: '#2E1956',
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ["0%", "-25%"],
            },
            itemStyle: {
                color: '#2E1956',
                borderColor: '#e6ebf8',
                borderWidth: 2,
            },
        },
    ];

    const option = {
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 12,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'item',
            formatter: function () {
                let tooltip = ''; // Inicializa a variável tooltip

                // Primeiro, adiciona o "Seu score"
                let yourScore = gaugeData.find(data => data.name === "Seu score");
                if (yourScore) {
                    tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + yourScore.itemStyle.color + '"></span> '
                        + yourScore.name + ': ' + yourScore.value + '%<br>';
                }

                // Depois, adiciona os outros scores
                gaugeData.forEach(data => {
                    if (data.name !== "Seu score") {
                        tooltip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + data.itemStyle.color + '"></span> '
                            + data.name + ': ' + data.value + '%<br>';
                    }
                });

                return tooltip;
            }
        },
        series: [
            {
                type: "gauge",
                radius: '85%',
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false,
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false,
                    itemStyle: {
                        borderWidth: 0,
                        // borderColor: "#464646",
                    },
                },
                axisLine: {
                    lineStyle: {
                        width: 40, // Aumentando a espessura das séries
                        // color: [
                        // [0.2, '#471FCC'],
                        // [0.4, 'rgba(200, 200, 200, 0.2)'],
                        // [0.4, '#8C40E3'],
                        // [0.6, 'rgba(200, 200, 200, 0.2)'],
                        // [0.6, '#DA43F2'],
                        // [1, 'rgba(200, 200, 200, 0.2)']
                        // ]
                    }
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                data: gaugeData,
                title: {
                    fontSize: 12,
                    fontFamily: 'Ubuntu',
                },
                detail: {
                    show: true,
                    formatter: '{value}%',
                    fontSize: 14,
                    fontFamily: 'Ubuntu',
                    color: 'inherit',
                    offsetCenter: [0, '70%'],
                },
            },
        ],
    };
    const createSecureCards = (mergedData: MergedDataItem[]): Card[] => {
        return mergedData.map((item) => {
            let colValue;
            let rowValue;
            let bgColor;
            let fontColor;
            let atualPercentScore
            let maxPercentScore

            switch (item.category) {
                case 'apps':
                    colValue = 7;
                    rowValue = 1;
                    atualPercentScore = atualPercentageApps
                    maxPercentScore = maxpercentageApps
                    break;
                case 'data':
                    colValue = 10;
                    rowValue = 3;
                    atualPercentScore = atualPercentageData
                    maxPercentScore = maxpercentageData
                    break;
                case 'identity':
                    colValue = 10;
                    rowValue = 1;
                    atualPercentScore = atualPercentageIdentity
                    maxPercentScore = maxpercentageIdentity
                    break;
                case 'device':
                    colValue = 7;
                    rowValue = 3;
                    atualPercentScore = atualPercentageDevices
                    maxPercentScore = maxpercentageDevices
                    break;
                default:
                    colValue = 5;
                    rowValue = 3;
            }

            return {
                bgColor: bgColor,
                fontColor: fontColor,
                type: 'Custom',
                position: {
                    row: rowValue,
                    col: colValue,
                    RowSpan: 2,
                    ColSpan: 3,
                },
                showFocusButton: true,
                ticketButtonLabel: "Ver politicas",
                title: () => { return item.category.charAt(0).toUpperCase() + item.category.slice(1) },
                focusedCardIndex: setFocus,
                customContentRender: (focused?: boolean, handleFocusClick?: Function) => {
                    setFocus(focused === true);
                    if (focused || focus) {
                        handleFocus(item);
                        setFocus(false);
                        return (
                            <div className="table-card">
                                <LcInfiniteTable
                                    loading={isLoading}
                                    columns={columns}
                                    status={getStatus}
                                    rows={filteredCategoryDetailList}
                                    loadMore={() => undefined}
                                    size={filteredCategoryDetailList.length}
                                    // filter={_filterAdvanced}
                                    functionGeneric={_functionGeneric}
                                    onSortChange={onSortChange}
                                    hidePagination={true}
                                    density='low'
                                    tooltipContentColumn='scorePercent'
                                />
                            </div>
                        )
                    } else {
                        if (focused === false) {
                            if (focus) {
                                setFilteredCategoryDetailList(filteredCategoryDetailList);
                            }
                        }
                        const backgroundColorObj = cardBackgroundColors?.find(x => x.name == item.category);
                        const backgroundColorValue = backgroundColorObj ? backgroundColorObj.color : 'white';
                        let score = item.scoreDetailList?.reduce((acc, { score }) => acc + score, 0);
                        let maxScore = item.scoreDetailList?.reduce((acc, { maxScore }) => acc + maxScore, 0);
                        let textValue = (score && maxScore) ? `${score.toFixed(0)}/${maxScore}` : '0/0';
                        let textTooltip = (atualPercentScore && maxPercentScore) ? `Sua porcentagem atual é ${((atualPercentScore * 100) / maxPercentScore).toFixed(0)}%` : `Sua porcentagem atual é 0%`

                        return (
                            <LcLoading loading={isLoading}>
                                <div className="securityIconValue">
                                    <IconValue
                                        icon={getIcon(item.category)}
                                        value={textValue}
                                        color={getColor(item.category)}
                                        backgroundColor={backgroundColorValue}
                                        cssClass="dividedInfo"
                                    />
                                    <ProgressBarWithLabel
                                        atualPercentScore={atualPercentScore}
                                        maxPercentScore={maxPercentScore}
                                        textTooltip={textTooltip}
                                    />
                                    <div className="reconmentations">
                                        <span
                                            onClick={() => {
                                                if (handleFocusClick) {
                                                    setTimeout(() => {
                                                        handleFocusClick();
                                                        setFocus(true);
                                                    }, 150);
                                                    // handleFocusClick();
                                                }
                                            }}
                                        >
                                            Ver recomendações
                                        </span>
                                    </div>
                                </div>
                            </LcLoading>
                        );
                    }
                }
            }
        });
    }

    const getInitialCards = () => {
        return [
            {
                type: 'Custom', position: { row: 1, col: 1, RowSpan: 3, ColSpan: 6 },
                showFocusButton: false,
                title: () => "Score de segurança de suas subscrições",
                ticketButtonLabel: "Abrir chamado",
                ticketButton: () => { return <PiHeadsetLight size={'32px'} onClick={() => { createTicket() }} /> },
                infoContent: () => {
                    return (
                        <span>
                            Este gráfico apresenta o score de segurança da sua
                            empresa (Meu score), permitindo uma comparação com
                            empresas de perfil semelhante (Empresas semelhantes) e a
                            média geral do mercado (Média de mercado). Esses
                            comparativos oferecem uma visão clara do desempenho de
                            segurança da sua empresa em relação ao cenário
                            competitivo.
                        </span>
                    );
                },
                customContentRender: (focused?: boolean) => {
                    return (
                        <LcLoading loading={isLoading}>
                            <ReactECharts option={option} style={{ height: '400px' }} />
                        </LcLoading>
                    )
                }
            },
            {
                type: 'Custom',
                position: { row: 4, col: 4, RowSpan: 1, ColSpan: 3 },
                showFocusButton: false,
                centeredContent: false,
                infoContent: () => <div>Dados disponibilizados pela Microsoft.</div>,
                title: () => {
                    return <>{
                        <div className="lc-title ellipsis">Alertas de fraude</div>
                    }</>
                },
                customContentRender: () => {
                    return (
                        <LcLoading loading={isLoading}>
                            <div className='fraudDiv'>
                                <IconValue
                                    title={"Microsoft 365 suite"}
                                    icon={getIconAndColor((fraudEventsReportCount ? fraudEventsReportCount : 0), "").icon}
                                    value={fraudEventsReportCount ? fraudEventsReportCount : 0}
                                    color={getIconAndColor((fraudEventsReportCount ? fraudEventsReportCount : 0), "").color}
                                    unity=""
                                    maximumFractionDigits={0}
                                    minimumFractionDigits={0}
                                    noDataLabel="Sem fraudes no período."
                                    formatValue={true}
                                    colorValue='#2E1956'
                                />
                                <p className='textInfo'>{fraudEventsReportCount === 0 ? 'Não existem alertas no momento' : 'Enviamos os detalhes por e-mail'}</p>
                            </div>
                        </LcLoading>
                    );
                }
            },
            {
                type: 'Custom',
                position: { row: 4, col: 1, RowSpan: 1, ColSpan: 3 },
                showFocusButton: false,
                ticketButtonLabel: "Abrir chamado",
                ticketButton: () => { return <PiHeadsetLight onClick={() => { createTicket() }} /> },
                title: () => "Qualifique seu ambiente",
                customContentRender: (focused?: boolean) => {
                    return (
                        <span className="lc-subtitle">Existem oportunidades de evolução em seu ambiente. <span className="link" onClick={() => { createTicket() }}><b>Abra um chamado</b></span> para fazermos proposições de avanço considerando as melhores práticas listadas.</span>
                    )
                }
            }
        ]
    }
    const translations = {
        description: {
            label: "Pesquisar",
            type: "text",
        },

    };

    const getInquireWithInstruction = async (Instruction) => {
        setLoadingSugggestion(true);
        setOpenAiModal(true);
        try {
            var textToSend = cleanText(Instruction);
            if (textToSend) {
                let response = await AixCompany.GetInquireWithInstruction(textToSend, 1, 3)
                const paragraphs = response.message.split('\n\n');
                setSuggestion(paragraphs)
            }
        } catch (error) {
            console.error(error)
        }
        setLoadingSugggestion(false);

    }

    const formatText = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/).filter(Boolean);
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <strong key={index}>{part.slice(2, -2)}</strong>;
            } else {
                const match = part.match(/^(\d+\.\s)(\*\*.*?\*\*:)/);
                if (match) {
                    return (
                        <strong key={index}>
                            {match[1]}{match[2].slice(2, -2)}
                        </strong>
                    );
                } else {
                    const listMatch = part.match(/^(\d+\.\s)(.*)/);
                    if (listMatch) {
                        const innerParts = listMatch[2].split(/(\*\*.*?\*\*)/).filter(Boolean);
                        return (
                            <span key={index}>
                                <strong>{listMatch[1]}</strong>
                                {innerParts.map((innerPart, innerIndex) => (
                                    innerPart.startsWith('**') && innerPart.endsWith('**') ?
                                        <strong key={innerIndex}>{innerPart.slice(2, -2)}</strong> :
                                        innerPart
                                ))}
                            </span>
                        );
                    }
                    return part;
                }
            }
        });
    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    data={selectedCategoryDetailList}
                    onFilteredData={(filteredData) => { setIsLoading(false); setFilteredCategoryDetailList(filteredData as any[]); }}
                    translations={translations}
                />
            </div>
        );
    };
    function clearState() {
        setCardBackgroundColors([]);
        setSecureScore(SecureDafeult);
    }

    useEffect(() => {
        const fetchSecureScores = async () => {
            setIsLoading(true);

            try {
                const response = await ServiceInstance.GetSecureScores();

                const { secureScoreCategoryList, averageComparativeScores } = response;

                const _backgroundColors = secureScoreCategoryList?.map((item) => ({
                    name: item.category,
                    color: 'white'
                }));

                const getCategoryScores = (categoryName) => {
                    const category = secureScoreCategoryList.find(categoryItem => categoryItem.category === categoryName);
                    if (category) {
                        const maxScore = category.scoreDetailList.reduce((acc, { maxScore }) => acc + maxScore, 0);
                        const actualScore = category.scoreDetailList.reduce((acc, { score }) => acc + score, 0);
                        return { maxScore, actualScore };
                    }
                    return { maxScore: 0, actualScore: 0 };
                };

                const appsScores = getCategoryScores('apps');
                const devicesScores = getCategoryScores('device');
                const identityScores = getCategoryScores('identity');
                const dataScores = getCategoryScores('data');

                setMaxPercentegeApps(appsScores.maxScore);
                setatualPercentegeApps(appsScores.actualScore);

                setMaxPercentegeDevices(devicesScores.maxScore);
                setAtualPercentegeDevices(devicesScores.actualScore);

                setMaxPercentegeIdentity(identityScores.maxScore);
                setAtualPercentegeIdentity(identityScores.actualScore);

                setMaxPercentegeData(dataScores.maxScore);
                setAtualPercentegeData(dataScores.actualScore);

                const addicionalDataScore = averageComparativeScores.find(average => average.basis === "TotalSeats");
                if (addicionalDataScore) {
                    const {
                        appsScore, appsScoreMax,
                        deviceScore, deviceScoreMax,
                        identityScore, identityScoreMax,
                        dataScore, dataScoreMax
                    } = addicionalDataScore.additionalData;

                }

                setCardBackgroundColors(_backgroundColors);
                setSecureScore(response);
            } catch (error: any) {
                setIsLoading(false);
                console.error(error);
                setShowFluxoCSP(true);
                enqueueSnackbar(error.message || 'An error occurred', {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            } finally {
                setIsLoading(false);
            }
        };
        ServiceInstance.GetFraudEventsReportByClientGroupId()
            .then(response => {
                setFraudEventsReportCount(response.data);
                setFraudEventsReportCountLoading(false);
            })
            .catch(error => {
                setFraudEventsReportCountLoading(false);
                if (error.response.data.message && typeof error.response.data.message == 'string') {

                    return
                }
            });
        fetchSecureScores();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        var filtered = selectedCategoryDetailList.filter((p: any) =>
            filter.term == '' ||
            p.description.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'desc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });
        setFilteredCategoryDetailList(filtered);
    }, [filter]);

    const getMaxPercent = (cat: string): number => {
        const maxPercentage = {
            apps: maxpercentageApps,
            Devices: maxpercentageDevices,
            data: maxpercentageData,
            identity: maxpercentageIdentity
        }

        return maxPercentage.hasOwnProperty(cat) ?
            maxPercentage[cat] : maxpercentageIdentity;
    }

    const columns = [
        {
            field: 'description', headerName: 'Oportunidades de evolução na segurança de seu ambiente', width: "68%", expandable: true, sort: false,
            renderCell: (row: any) => {
                return (
                    ReactHtmlParser(row.description)
                )
            }
        },
        {
            field: 'scorePercent', headerName: '% Implementado', width: "10%", align: 'center',
            renderCell: (row: any) => {
                return (
                    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                        {Math.round(row.scorePercent)}%
                    </div>
                )
            }
        },
        {
            field: 'score', headerName: '% Impacto no Score', width: "10%", align: 'center',
            renderCell: (row: any) => {
                let maxScore: number = getMaxPercent(row.category);
                return (
                    <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                        + {((row.maxScore * 100) / maxScore).toFixed(2).replace('.', ',')}%
                    </div>
                )
            }
        },
        {
            field: 'assitentAI', width: '3%', overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return (
                    <LcIconLink
                        icon='ppi ppi-lightning-bolt'
                        id='aiLCChamados'
                        degrade
                        tooltip="Assistente de AI"
                        tooltipPosition='right'
                        onClick={() => {
                            getInquireWithInstruction(row.description);
                            setRowAI(row)
                            setShowImplementationStatus(true);
                        }}
                    />
                )
            }
        },
        {
            field: 'implementationStatus', width: '3%', overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return (
                    <LcIconLink
                        icon={<PiFileTextLight />}
                        tooltip="Status da implementação"
                        tooltipPosition='right'
                        onClick={() => {
                            setImplementationStatus(row.implementationStatus);
                            setScorePercent(Math.round(row.scorePercent));
                            setShowImplementationStatus(true);
                        }}
                    />
                )
            }
        },
        {
            field: 'implementation', width: "3%", overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return (
                    <LcIconLink
                        icon={<PiHeadsetLight />}
                        tooltip={row.scorePercent !== 100 ? "Implemente a política" : "Política já implementada"}
                        disabled={row.scorePercent === 100}
                        tooltipPosition='right'
                        onClick={() => createTicket(row)}
                    />
                )
            }
        }
    ];

    const getStatus = (row: { scorePercent: number }): string => {
        if (row.scorePercent === 0) {
            return "danger";
        } else if (row.scorePercent > 0 && row.scorePercent < 100) {
            return "warning";
        } else if (row.scorePercent === 100) {
            return "success";
        } else {
            return "primary";
        }
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const _functionGeneric = [
        {
            title: 'Abrir Chamado',
            icon: <PiHeadsetLight />,
            tooltip: "Abrir Chamado",
            func: () => { createTicket() }
        },
        {
            title: 'Filtro',
            icon: <PiFunnelLight />,
            tooltip: "Filtrar",
            func: () => {
                setOpenAiModal(false)
                setOpenFilter(!openFilter)
            }
        }
    ];

    const createTicket = (row?: any) => {
        if (row) {

            const rawDescription = row.description.replace(/<[^>]*>/g, '');;

            setFormData({
                title: `Melhorias no contexto de segurança.`,
                description: `Olá, gostaria de implementar esta política: ${rawDescription}`,
                gtmType: 9
            })
        } else {
            setFormData({
                title: `Melhorias no contexto de segurança.`,
                description: `Olá, gostaria de sugestões de melhoria no contexto de segurança do ambiente da ${user.ClientGroupSelectedDescription}.`,
                gtmType: 9
            });
        }
        setModalVisibleHeyHoCall(true);
    }

    const handleClickDetails = (item, category: any) => {
        if (item && item.category === category && item.scoreDetailList) {
            setCardBackgroundColors([]);
            setSelectedCategoryDetailList([]);
            setFilteredCategoryDetailList([]);
            let _scoreDetail = item.scoreDetailList.map((scoreDetail: any, index: number) => {
                return { ...scoreDetail, id: index, category: item.category };
            })
                .sort((a, b) => a.scorePercent - b.scorePercent)
                .sort((a, b) => {
                    let maxScoreA: number = (a.maxScore * 100) / getMaxPercent(a.category);
                    let maxScoreB: number = (b.maxScore * 100) / getMaxPercent(b.category);
                    if (a.scorePercent == b.scorePercent)
                        return maxScoreB - maxScoreA;
                });

            let _backgroundColors = cardBackgroundColors.map((item: any) => {
                if (item.name == item.category) {
                    return { ...item, color: '#dde0ed' }
                } else {
                    return { ...item, color: 'white' }
                }
            });
            setCardBackgroundColors(_backgroundColors);
            setSelectedCategoryDetailList(_scoreDetail);
            setFilteredCategoryDetailList(_scoreDetail);
            setIsLoading(false)
        }
    }

    const handleFocus = (focusedItem) => {
        setIsLoading(true);
        setItemDatail(focusedItem);
        setCategoryItem(focusedItem.category);
    }

    const loadSecure = async () => {
        if (secureScore && secureScore.secureScoreCategoryList) {
            const mergedData = mergeDataWithDefaults(secureScore.secureScoreCategoryList);
            const secureCards = createSecureCards(mergedData);
            const initialCards = getInitialCards();

            const cardAs = [...initialCards, ...secureCards]
            setCards(cardAs)
        }
    }
    useEffect(() => {
        loadSecure();
    }, [secureScore, filteredCategoryDetailList]);

    useEffect(() => {
        if (itemDatail && categoryItem)
            handleClickDetails(itemDatail, categoryItem)
    }, [itemDatail, categoryItem]);

    const assistenteAI = () => (
        <>
            <button
                className={`lc-button mb-4 bg-${rowAI.scorePercent !== 100 ? 'primary' : 'secondary disabled'} `}
                disabled={rowAI.scorePercent === 100}
                onClick={() => rowAI !== undefined && createTicket(rowAI)}
            >{rowAI !== undefined && rowAI.scorePercent !== 100 ? "Implemente esta política" : "Política já implementada"}</button>
            {
                openAiModal ?
                    <LcLoading loading={loadingSugggestion} label='Gerando análise, aguarde...'>
                        <div className="suggestion-content scrollable-v" style={{ alignSelf: 'center' }}>
                            {suggestion.map((paragraph, index) => {
                                // Check if the paragraph starts with a number indicating a list item
                                const isList = /^\d+\./.test(paragraph);

                                if (isList) {
                                    // Split the list items
                                    const listItems = paragraph.split('\n').map(item => item.trim()).filter(item => item);

                                    return (
                                        <div key={index} className='mt-2'>
                                            {listItems.map((item, itemIndex) => (
                                                <p key={itemIndex} className='suggestion-paragraph'>{formatText(item)}</p>
                                            ))}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <p key={index} className='mt-2 suggestion-paragraph'>{formatText(paragraph)}</p>
                                    );
                                }
                            })}
                        </div>

                    </LcLoading>
                    :
                    <div className="sideModalReport">
                        <div className="rowContent">
                            {
                                ReactHtmlParser(
                                    scorePercent > 0
                                        ? (implementationStatus ? implementationStatus : 'O fornecedor não disponibilizou mais informações sobre o status dessa implementação')
                                        : 'Sem status de implementação'
                                )
                            }
                        </div>
                    </div>
            }
        </>
    );

    return (
        <Layout pageTitle="Security Score">
            {
                showFluxoCSP ?
                    <div className="lc-segment">
                        <div className="body">
                            Para utilizar as funcionalidades de Tech - EA/EAS, é necessário
                            fornecer e/ou atualizar as credenciais de acesso conforme os passos abaixo. . Caso precise de ajuda abra uma requisição, clicando <span className="link text-link" onClick={() => { history.push('/Chamados'); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }}>aqui</span>, e teremos o prazer em atender.
                            <img src={ImgFluxo} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                    :
                    <LCDashboard
                        cards={cards}
                        rightSidePanel={{
                            title: openAiModal && !openFilter ? 'LiveCloud AI' : 'Filtros',
                            titleClass: openAiModal && !openFilter ? 'ai-gradient-text' : undefined,
                            focusable: openAiModal && !openFilter,
                            content: openAiModal && !openFilter ? assistenteAI : filterSystem,
                            pinned: false,
                            hidePinWhenFocus: true,
                            show: openAiModal || openFilter,
                            close: () => {
                                setOpenFilter(false);
                                setOpenAiModal(false);
                                setImplementationStatus('');
                                setShowImplementationStatus(false);
                            }
                        }}
                    />
            }

            <HeyHoNewRequest enebleIA formData={formData} openModal={modalVisibleHeyHoCall} onClose={() => setModalVisibleHeyHoCall(false)} needAtachmente />
        </Layout>
    );
}

export default CSPSecurityScore;