import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Layout from '../../../components/Layout/Layout';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { SystemDetail } from '../../../services/patchmanager/patchManagerModel';
import moment from 'moment';
import { PiAppleLogoLight, PiFunnelDuotone, PiFunnelLight, PiInfoLight, PiLinuxLogoLight, PiWindowsLogoLight } from 'react-icons/pi';
import LcTooltip from '../../../components/Generic/LcTooltip';
import './index.css';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import FilterPanel from '../../../components/Data/FilterPanel';
import { PatchManagerState } from '../../../store/reducers/patchManagerReducer';
import { SystemModalDetails } from './SystemModalDetails';
import { getStatusRow, translateStatus, translateHealthColor, translateHealth, translateScanStatusPatch } from '../utils/translations';


const SystemListPatchManager: React.FC = () => {
    const queryClient = useQueryClient();
    const patchManager = useSelector<RootState, PatchManagerState>(state => state.patchManager);
    const patchManagerService = new PatchManagerService({});
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [systemSelected, setSystemSelected] = useState<SystemDetail | null>(null);
    const [systemListFiltered, setSystemListFiltered] = useState<SystemDetail[]>([]);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [ clearFilter, setClearFilter ] = useState<boolean>(true);
    const [reducerFilter , setReducerFilter] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useDispatch();

    const { data: systemList = [] } = useQuery('systemList', async () => {
        setLoading(true)
        const response = await patchManagerService.GetSystemByPlatformList();
        return response;
    }, {
        keepPreviousData: true, 
        refetchOnWindowFocus: false,
        onSuccess: data => {
            if (data.length === 0) {
                queryClient.invalidateQueries('todos');
        // Invalidate the cache if data is empty
            } else {
                if(patchManager.OsSystemName && patchManager.OsSystemName !== '') {
                    getFilterReducer(data)
                } else {
                setSystemListFiltered(data)
                setReducerFilter('')
                setClearFilter(true);
                }
            }
            setLoading(false)
        },
    });

    const getFilterReducer = useCallback((list) => {
            let osName = {
                service_pack:[patchManager.OsSystemName]
            }
            setReducerFilter(osName)
            setSystemListFiltered(list.filter((systemList:SystemDetail ) => systemList.service_pack === patchManager.OsSystemName)); 
            setClearFilter(false);
            // setTimeout(() => {
                dispatch({ type: 'RESET_SYSTEM_PATCH'});
            // },100)
    },[dispatch, patchManager])

    const card:Card[] = [
        {
            id: 1,
            type: 'Custom',
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} label='Carregando...'>
                        <LcInfiniteTable
                            rows={systemListFiltered}
                            columns={columnsSystem}
                            size={systemListFiltered.length}
                            hidePagination
                            fontSize='medium'
                            status={getStatusRow}
                            notLoadMore
                            tooltipContentColumn='computer_live_status'
                            customTooltipContentColumn={translateStatus}
                        />
                    </LcLoading>
                );
            }
        }
    ]

    const translations = {
        resource_name: {
            label: "HostName",
            type: "multiSelect"
        },
        service_pack: {
            label: "Sistema operacional",
            type: "multiSelect",
            // topDropdown: true,
        },
        computer_live_status: {
            label: "Status",
            type: "multiSelect",
            values: {
                1: 'Ligado',
                2: "Desligado",
                3: 'Desconhecido',
            }
        },
        resource_health_status: {
            label: "Saúde",
            type: "multiSelect",
            values: {
                0: "Desconhecido",
                1: "Saudável ",
                2: "Vulnerável",
                3: "Altamente vulnerável"
            }
        },
        agent_last_contact_time: {
            label: "Última comunicação",
            type: "spanDateTime"
        },
        // 'resourcetorebootdetails.reboot_req_status': {
        //     label: "Reboot Necessário",
        //     type: "multiSelect",
        //     values: {
        //         true: 'Sim',
        //         false: 'Não'
        //     }
        // },
    };

    const columnsSystem = [
        {
            field: "resource_name", headerName: "HostName", width: "20%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                        {row.os_platform_name && <LcIconLink notALink icon={
                            row.os_platform_name.toLocaleLowerCase().includes('windows') ? <PiWindowsLogoLight size={'1rem'} />
                                :
                                row.os_platform_name.toLocaleLowerCase().includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />}
                        <span style={{ color: 'var(--text-link)', fontWeight: 400, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { setModalOpen(true); setSystemSelected(row); }} className='font-4x'>{row.resource_name}</span>
                    </div>
                )
            }
        },
        {
            field: "resource_health_status", headerName: "Status de vulnerabilidade", width: "15%", align: 'left', fontSize: '11px', overflow: 'visible',
            renderCell: (row: SystemDetail) => {
                return <div>
                    <div style={{ width: '100%', borderRadius: '100%', color: translateHealthColor(row.resource_health_status) }}>{translateHealth(row.resource_health_status)}</div>
                </div>
            }
        },
        {
            field: "agent_last_contact_time", headerName: "Última comunicação", width: "12%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return moment(row.agent_last_contact_time).format('DD/MM/YYYY hh:mm')
            }
        },
        // {
        //     field: "agent_last_bootup_time", headerName: "Último reboot", width: "12%", align: 'left', fontSize: '11px',
        //     renderCell: (row: SystemDetail) => {
        //         return moment(row.agent_last_bootup_time).format('DD/MM/YYYY hh:mm')
        //     }
        // },
        {
            field: 'resourcetorebootdetails.reboot_req_status', headerName: "Reboot", width: "15%", align: 'center', overflow: "visible", fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (
                    <>
                        {
                            (row['resourcetorebootdetails.reboot_req_status']) !== false ?
                            <LcIconLink 
                                size='medium' 
                                notHover
                                clickble={false}
                                icon={<PiInfoLight color='#ffb530' size={22} />}
                                tooltip="Necessário reiniciar a máquina"
                                tooltipPosition='right'
                            />	
                            : 
                            <LcTooltip 
                                trigger='hover'
                                position='right'
                                content={`Último reboot: ${moment(row.agent_last_bootup_time).format('DD/MM/YYYY hh:mm')} `}
                        >
                            Não
                            </LcTooltip>
                        }
                    </>
                )
            }      
        },
        {
            field: "service_pack", headerName: "Sistema operacional", width: "28%", align: 'left', overflow: 'visible', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return row.service_pack ?
                    <div className='operationalSystem'>
                        <p>{row.service_pack}</p>
                    </div>
                    :
                    <div className='operationalSystem'>{row.service_pack}</div>
            }
        },
        {
            field: "scan_status", headerName: "Último scan", width: "10%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemDetail) => {
                return (<div className='agentStatus'> {translateScanStatusPatch(row.scan_status)}</div>);
            }
        },
        {
            field: "critical_patch_count", headerName: "Patches pendentes", width: "15%", align: 'left', overflow: 'visible',
            renderCell: (row: SystemDetail) => {
                let allSum = row.missing_bios_patches + row.missing_driver_patches + row.missing_ms_patches + row.missing_tp_patches;
                let classifiedSum = row.critical_patch_count + row.important_patch_count + row.moderate_patch_count + row.moderate_patch_count + row.low_patch_count;
                var unclassifiedSum = allSum - classifiedSum;
                if (unclassifiedSum < 0) {
                    unclassifiedSum = 0;
                }
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <LcTooltip trigger='hover' position='left' content="Crítico">
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.critical_patch_count > 0 ? '#dc291e' : '#e2e6f3' }}>
                                {row.critical_patch_count > 99 ? 99 : row.critical_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Importante" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.important_patch_count > 0 ? '#ffa400' : '#e2e6f3' }}>
                                {row.important_patch_count > 99 ? 99 : row.important_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Moderado" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.moderate_patch_count > 0 ? '#f6de00' : '#e2e6f3' }}>
                                {row.moderate_patch_count > 99 ? 99 : row.moderate_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='left' content="Baixo" >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: row.low_patch_count > 0 ? '#3ebb3f' : '#e2e6f3' }}>
                                {row.low_patch_count > 99 ? 99 : row.low_patch_count}
                            </div>
                        </LcTooltip>
                        <LcTooltip trigger='hover' position='right' content="Não classificado"  >
                            <div className='severityBox' style={{ color: '#fff', backgroundColor: unclassifiedSum > 0 ? '#cccccf' : '#e2e6f3' }}>
                                {unclassifiedSum > 99 ? 99 : unclassifiedSum}
                            </div>
                        </LcTooltip>
                    </div>
                )
            }
        },
    ];

    //affected_patch_status
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    reducerFilter={reducerFilter}
                    data={systemList}
                    onFilteredData={(dataFilteredParm) => {
                        setClearFilter(false);
                        setSystemListFiltered(dataFilteredParm as SystemDetail[]);
                    }}
                    clearFilters={() => {setClearFilter(true); setSystemListFiltered(systemList);setReducerFilter(''); }}
                    translations={translations} 
                    filter={Object.keys(translations).map(key => ({ 
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}                
                    />
            </div>
        );
    };

    const items = [
        {
            icon: clearFilter ? <PiFunnelLight color='var(--icon-default)' /> : <PiFunnelDuotone color='var(--color-secondary-lime)' className='notHover' />,
            tooltip: "Filtro",
            onClick: () => setShowFilterPanel(!showFilterPanel),
            clickble: showFilterPanel,
            color: clearFilter ? '' : 'var(--color-secondary-lime)'
        },
    ];

    return (
        <Layout
            pageTitle='Sistemas'
            functionsGeneric={items}
            // periodSelection={periodSelection}
            // changePeriod={periodFilterChange}
            row={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                    <div style={{ fontWeight: 700 }} className='size'>{systemListFiltered.length}</div>
                    &nbsp;
                    <span> Sistemas</span>
                </div>
            }
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: showFilterPanel,
                    close: (e) => setShowFilterPanel(e),
                    content: () => filterSystem()
                }}
            />
            <SystemModalDetails modalOpen={modalOpen} setModalOpen={setModalOpen} systemSelected={systemSelected}/>
        </Layout>
    );
};

export default SystemListPatchManager;
