import React, { useCallback, useEffect, useState } from 'react';
import exchangeLogo from '../../../backupSaaSV2/icons/exchange-logo.svg'
import sharepointLogo from '../../../backupSaaSV2/icons/sharepoint-logo.svg'
import teamsLogo from '../../../backupSaaSV2/icons/teams-logo.svg'
import onedriveLogo from '../../../../assets/images/oneDriveIcon.svg'
import microsoft365 from '../../../../assets/images/microsoft365.svg'
import './index.css';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { SoftawareAcoountByMonthModel } from '../../../../services/saas/SaasModel';
import { defaultTooltipConfig } from '../../../../components/Generic/eChartsConfig';

interface Props{
    data: SoftawareAcoountByMonthModel[];
    showInfo: boolean;
    showMonthlyPeek?: boolean

}
const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

const SoftwareAccountsByMonth: React.FC<Props> = ({data,showInfo,showMonthlyPeek}) => {
    const [option, setoption] = useState<any>()
    const [infoAccount, setInfoAccount] = useState<any[]>([])
    const [monthlyPeakData, setMonthlyPeakData ] = useState<Record<string, number>>()

    const getMonthlyPeak = (reportData: SoftawareAcoountByMonthModel[], mes?: number): Record<string, number> => {
        const simulatedDate = moment();
        let currentDate = simulatedDate.clone();
        if (currentDate.date() <= 2) {
            currentDate = currentDate.subtract(2, 'days');
        }
        const targetMonth = mes ? mes : currentDate.month(); 
        const currentYear = currentDate.year();
        const monthlyData: Record<string, number[]> = {
            exchange: [],
            office365: [],
            oneDrive: [],
            sharePoint: [],
            teams: []
        };

        const dataDesiredMonth= reportData.filter(entry => {
            const reportDate = moment(entry.reportDate);
            return reportDate.month() === targetMonth && reportDate.year() === currentYear;
        });
        const monthlyPeak : Record<string, number> = {};
        if(dataDesiredMonth.length > 0) {
            dataDesiredMonth.forEach(entry => {
                monthlyData.exchange.push(entry.exchange);
                monthlyData.office365.push(entry.office365);
                monthlyData.oneDrive.push(entry.oneDrive);
                monthlyData.sharePoint.push(entry.sharePoint);
                monthlyData.teams.push(entry.teams);
            });
            for (const field in monthlyData) {
                if (monthlyData.hasOwnProperty(field)) {
                    monthlyPeak[field] = Math.max(...monthlyData[field]);
                }
            }
        }
        return monthlyPeak;
    }

        
    const getChartOptions = useCallback((currentMonthIndex, previousMonthIndex, mostUpdatedMonthData, lastMonthData) => {
        const xAxisCategories = [month[previousMonthIndex], month[currentMonthIndex]];
        return {
            legend: {
                icon: 'circle',
                bottom: showInfo ? 25 : 0,
                left: '5%',
                width: '100%',
                align: 'left',
                itemGap: showInfo ? 30 : 100,
                textStyle: {
                    fontFamily: 'Ubuntu',
                    fontSize: 10,
                    marginRight: 20,
                },
            },
            grid: {
                height: 'auto',
                top: '15%',
                left: '0%',
                right: '0%',
                bottom: showInfo ?'25%' : '15%',
                containLabel: true
            },
            tooltip: {
                ...defaultTooltipConfig,
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            dataset: {
                dimensions: ['product', 'Microsoft 365', 'Teams', 'Exchange', 'OneDrive', 'SharePoint'],
                source: [
                    { product: xAxisCategories[0], 'Microsoft 365': lastMonthData.office365, Teams: lastMonthData.teams, Exchange: lastMonthData.exchange, OneDrive: lastMonthData.oneDrive, SharePoint: lastMonthData.sharePoint },
                    { product: xAxisCategories[1], 'Microsoft 365': mostUpdatedMonthData.office365, Teams: mostUpdatedMonthData.teams, Exchange: mostUpdatedMonthData.exchange, OneDrive: mostUpdatedMonthData.oneDrive, SharePoint: mostUpdatedMonthData.sharePoint },
                ]
            },
            xAxis: {
                type: 'category',
                data: xAxisCategories,
                axisLabel: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                }
            },
            yAxis: {
                axisLabel: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
            series:  [
                { type: 'bar', itemStyle: { color: '#00dbff' }, label: { show: true, position: 'top', textStyle: { color: '#8B90A39C' } } },
                { type: 'bar', itemStyle: { color: '#0772ab' }, label: { show: true, position: 'top', textStyle: { color: '#8B90A39C' } } },
                { type: 'bar', itemStyle: { color: '#28a8ea' }, label: { show: true, position: 'top', textStyle: { color: '#8B90A39C' } } },
                { type: 'bar', itemStyle: { color: '#471fcc' }, label: { show: true, position: 'top', textStyle: { color: '#8B90A39C' } } },
                { type: 'bar', itemStyle: { color: '#34c38f' }, label: { show: true, position: 'top', textStyle: { color: '#8B90A39C' } } }
            ]
        };
    },[showInfo]);

    const buildDivInfo = useCallback((lastMonthData:  Record<string, number>, currentMonthData: Record<string, number>) => {
        const products = [
            { key: 'office365', name: 'Microsoft 365' },
            { key: 'teams', name: 'Teams' },
            { key: 'oneDrive', name: 'OneDrive' },
            { key: 'exchange', name: 'Exchange' },
            { key: 'sharePoint', name: 'SharePoint' }
        ];
    
        const arrayInfo: any = [];
        for (const product of products) {
            const lastValue = lastMonthData[product.key];
            const currentValue = currentMonthData[product.key];
    
            if (lastValue !== currentValue) {
                const isDecrease = lastValue > currentValue;
                const percentage = calculatePercentageDifference(isDecrease ? lastValue : currentValue, isDecrease ? currentValue : lastValue);
    
                arrayInfo.push({
                    product: product.name,
                    porcentagem: percentage,
                    query: isDecrease ? "baixou" : "aumentou",
                    current: currentValue,
                    last: lastValue,
                });
            }
        }
    
        setInfoAccount(arrayInfo);
    },[])

    useEffect(() => {
        const simulatedDate = moment();
        let currentDate = simulatedDate.clone();
        if (currentDate.date() <= 2) {
            currentDate = currentDate.subtract(2, 'days');
        }
        
        const firstDayOfCurrentMonth =currentDate.startOf('month')
        const currentMonthIndex = currentDate.month();
        const previousMonthIndex = currentDate.startOf('month').subtract(1, 'month').month();
        let mostUpdatedMonthData = getMonthlyPeak(data);
        let lastMonthData : Record<string, number> | null = null;
        if (Object.keys(mostUpdatedMonthData).length !== 0) {
            lastMonthData = getMonthlyPeak(data, firstDayOfCurrentMonth.month());
            mostUpdatedMonthData = getMonthlyPeak(data, currentMonthIndex);
        } else {
            lastMonthData = getMonthlyPeak(data, currentMonthIndex);
        }
        
        setMonthlyPeakData(mostUpdatedMonthData);
        buildDivInfo(lastMonthData, mostUpdatedMonthData);
        setoption( getChartOptions(currentMonthIndex, previousMonthIndex, mostUpdatedMonthData, lastMonthData));
    }, [buildDivInfo, data, getChartOptions, showInfo]);


    const calculatePercentageDifference = (maior: number, menor: number) => {
        return (((maior - menor)/maior)*100).toFixed(1) ;
    }

    return(
    <div className='softwareAccountsByMonth'>
        <div className='softwareAccount'>
            {
                monthlyPeakData && showMonthlyPeek &&
                <div className="headerLogos row">
                    <div className='row '>
                        <img src={microsoft365} height={24} width={24} alt='Microsoft 365' />
                        <div className='ml-3'>
                            <h5>{monthlyPeakData.office365 }</h5>
                            <p>Microsoft 365</p>
                        </div>
                    </div>
                    <div className='row '>
                        <img src={teamsLogo} height={24} width={24} alt='Teams'/>
                        <div className='ml-3'>
                            <h5>{monthlyPeakData.teams }</h5>
                            <p>Teams</p>
                        </div>
                    </div>
                    <div className='row '>
                        <img src={exchangeLogo} height={24} width={24} alt='Exchange'/>
                        <div className='ml-3'>
                            <h5>{monthlyPeakData.exchange }</h5>
                            <p>Exchange</p>
                        </div>
                    </div>
                    <div className='row '>
                        <img src={onedriveLogo} height={24} width={24} alt='OneDrive' />
                        <div className='ml-3'>
                            <h5>{monthlyPeakData.oneDrive?? 0 }</h5>
                            <p>OneDrive</p>
                        </div>
                    </div>
                    <div className='row '>
                        <img src={sharepointLogo} height={24} width={24} alt='Sharepoint' />
                        <div className='ml-3'>
                            <h5>{monthlyPeakData.sharePoint?? 0 }</h5>
                            <p>SharePoint</p>
                        </div>
                    </div>
                </div>
                }
            <div className={showInfo ?' graphAccount normalWidth' :' graphAccount fullwidth'}>
                {
                    option &&
                        <ReactECharts
                            className={showInfo ?'normalWidth' :'fullwidth'}
                            opts={{ renderer: 'svg', locale: 'PT-br' }}
                            style={{ width: '100%', height: '100%' }}
                            option={option}
                        />
                }
            </div>
        </div>
        {
            
            <div className={`infoAccount ${showInfo? 'show': 'hidden'} scrollable-v`}>
                
                {
                    infoAccount.length > 0 && infoAccount.map(info => {
                        return (
                        <div key={info.product} className='row mb-5 '>
                            <i className='ppi ppi-lightning-bolt' style={{fontSize: '20px', transform: 'rotate(1deg)'}}></i>
                            <p className='ml-5'><strong className={info.product.toLowerCase()}>{info.product}</strong> {info.query} a quantidade de usuários em {info.porcentagem}% {`de (${info.last} para ${info.current})`}</p>
                        </div>
                        )
                    })

                }
            </div>
        }
    </div>
  );
}

export default SoftwareAccountsByMonth;