import React, { RefObject, useEffect, useRef, useState, useCallback } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import "echarts/i18n/langPT-br";
import Layout from '../../../components/Layout/Layout';
import { FinOpsDashBoard, FinOpsAdvanceFilterService, MonthBudget } from '../../../services/finOps/finOpsService';
import FinOpsMapManagerService, { FinOpsAllocationRule } from '../../../services/finOpsManagerService';
import { formatDecimal, getSymbolByCurrency } from '../../../services/currency';
import Alert from '../../../components/Dialog/Alert';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import FinOpsNestedCostCenterCosts from '../finOpsNestedCostCenterCosts';
import ConditionFilterResume from './conditionFilter';
import LcLoading from '../../../components/Generic/LcLoading';
import { FinOpsChartBuilder } from '../../../services/finOps/FinOpsChartBuilder';
import { PiArrowDownLight, PiArrowSquareOutLight, PiArrowUpLight, PiFunnelDuotone, PiFunnelLight, PiInfoLight, PiPiggyBankLight, PiWarningLight, } from 'react-icons/pi';
import PPDropDown from "../../../components/PPDropDown";
import { FinopsRecommendationsService } from '../../../services/finOps/finOpsRecommendations';
import history from '../../../history';
import { useQuery } from 'react-query';
import LcNoData from '../../../components/Generic/LcNoData';
import LcTooltip from '../../../components/Generic/LcTooltip';
import Confirmation from '../../../components/Dialog/Confirmation';
import { Tooltip } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import MessageService from '../../../services/AIx/messages/MessageService';
import LcIconLink from '../../../components/Generic/LcIconLink';
import AIxChatComponent from '../../LiveWatch/Insights/AIxChatComponent';
import { CostCenterBudgetChart } from './CostCenterBudgetChart';
import { TopConsumptionChart } from './TopConsumptionChart';

interface FinOpsResumeProps {
    isHome?: boolean,
    qtdGroups?: number
}
type filter = { contracts: string | undefined, date: string | undefined, idCostCenter: string | undefined, tipoDePeriodo: string | undefined };

function Currency({ amount }) {
    const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount);
    return <div>{formattedAmount}</div>;
}

function CurrencyWithKLC({ value, displayCurrencySymbol = true, displayDecimals = true }) {

    if (value == null || value === 'NaN' || isNaN(Number(value))) {
        return <div>0.00</div>;
    }

    const numValue = Number(value);
    const formatOptions: Intl.NumberFormatOptions = {
        style: displayCurrencySymbol ? 'currency' : 'decimal',
        currency: 'BRL',
        minimumFractionDigits: displayDecimals ? 2 : 0,
        maximumFractionDigits: displayDecimals ? 2 : 0
    };

    // billions and above
    if (numValue >= 1.0e+9) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', formatOptions).format((numValue / 1.0e+9));
        return <div>{formattedAmount + "B"}</div>;
    }
    // millions
    else if (numValue >= 1.0e+6) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', formatOptions).format((numValue / 1.0e+6));
        return <div>{formattedAmount + "M"}</div>;
    }
    // thousands
    else if (numValue >= 1.0e+3) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', formatOptions).format((numValue / 1.0e+3));
        return <div>{formattedAmount + "k"}</div>;
    }
    else {
        const formattedAmount = new Intl.NumberFormat('pt-BR', formatOptions).format((numValue));
        return <div>{formattedAmount}</div>;
    }
}

const beginOfMonth = () => {
    const currentDate = new Date();

    // Get the day of the month
    const dayOfMonth = currentDate.getDate();

    // Initialize a variable to store the result
    let result = false;

    // Check if the day of the month is greater than 5
    if (dayOfMonth > 0) {
        // If it's greater than 5, set the result to the current month
        result = false;// currentDate.getMonth() + 1; // Adding 1 because months are 0-based
    } else {
        // If it's not greater than 5, set the result to true
        result = true;
    }
    return result;
}

const PercentComponent = ({ number }) => {
    if (number == null) {
        return <span className='subtitle-dash-bold'>0</span>;
    }
    return <span className='subtitle-dash-bold'>{`${(number * 100).toFixed(2)}%`}</span>;
};

const serviceChartPalette = ['#471FCC', '#2E1956', '#8C40E3', '#AB19E7', '#966CF3', '#DA43F2', '#9FA3FF', '#00DBFF', '#C9FF46', '#34C38F'];
export const resourceChartPalette = ['#471FCC', '#2E1956', '#8C40E3', '#AB19E7', '#966CF3', '#DA43F2', '#9FA3FF', '#00DBFF', '#A6FFE8', '#34C38F'];
const totalChartPalette = ['#966CF3', '#E07817', '#F3C944', '#d6c241', '#babb41', '#9fb243', '#86a946', '#6f9f4a', '#59944d'];
export const costCenterChartPalette = ['#2E1956', '#471FCC', '#255A4B', '#34C38F', '#C9FF46', '#A6FFE8', '#8C40E3', '#DA43F2', '#AB19E7', '#9FA3FF'];

const Palletes = [{ graphGroup: 'service', pallete: serviceChartPalette },
{ graphGroup: 'resource', pallete: resourceChartPalette },
{ graphGroup: 'all', pallete: totalChartPalette },
{ graphGroup: 'costCenter', pallete: costCenterChartPalette }]

export const rankTypeOptions = [
    { label: 'Centro de Custo', value: 'costCenter' },
    { label: 'Serviço', value: 'service' },
    { label: 'Recurso', value: 'resource' }
];

const FinOpsResume: React.FC<FinOpsResumeProps> = (props) => {
    const finOpsChartService = new FinOpsChartBuilder(props);
    const [assistantid, setAssistantid] = useState<string>("");
    const [aixAgentVisible, setAIXAgentVisible] = useState(false);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<any>("");
    const [loadingCards, setLoadingCards] = useState<boolean[]>([false, false, true, true, true, true, true, true]);
    const [daysRemaining, setDaysRemaining] = useState<any>();
    const [graphType, setGraphType] = useState<string>('3');
    const [graphGroupType, setGraphGroupType] = useState<string>('all');
    const [rankType, setRankType] = useState<string>('resource');
    const [costCenters, setCostCenters] = useState<any[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [month, setMonth] = useState<string>('0');
    const [year, setYear] = useState<string>('0');
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>("");
    const [selectedContractId, setSelectedContractId] = useState<any>("");
    const [selectedCostCenterAux, setSelectedCostCenterAux] = useState<any>("");
    const [selectedContractIdAux, setSelectedContractIdAux] = useState<any>("");
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(beginOfMonth() ? 'priormonth' : 'currentmonth');
    const [confirmation, setConfirmation] = useState(false);
    const [cores, setCores] = useState<string[]>([]);
    const [finOpsAlertsHistory, setFinOpsAlertsHistory] = useState<any[]>([]);
    const [chartType, setChartType] = useState("stack");
    const [pageExitConfirmation, setPageExitConfirmation] = useState<boolean>(false)
    const [filtered, setFiltered] = useState<boolean>(false)
    const [periordText, serPeriodText] = useState<string | undefined>(undefined);
    const dispatch = useDispatch();
    const [totalDecimalRecommendation, setTotalDecimalRecommendation] = useState(0.00);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [filter, setFilter] = useState<filter>({
        contracts: '',
        date: moment().format('YYYY-MM'),
        idCostCenter: '',
        tipoDePeriodo: ''
    });
    const defaultFilter: FinOpsAllocationRule = {
        id: 0,
        order: 1,
        default: false,
        description: 'Filtro',
        conditions: [{
            idCondition: 0,
            field: '',
            operation: 'contains',
            filter: '',
            logicOperator: ''
        }],
        costCenters: [],
        contracts: []
    };

    const [filterConditions, setFilterConditions] = useState<FinOpsAllocationRule>(defaultFilter);

    const graphOptions = [
        { value: '1', label: 'Até o momento' },
        { value: '2', label: 'No periodo' },
        { value: '3', label: 'Acumulado' },
        { label: 'Total', value: 'all' },
        { label: 'Centro de Custo', value: 'costCenter' },
        { label: 'Serviço', value: 'service' }
    ];

    const getGraphGroupTypeOptions = () => {
        const baseOptions = [
            { label: 'Total', value: 'all' },
            { label: 'Centro de Custo', value: 'costCenter' },
            { label: 'Serviço', value: 'service' }
        ];

        return baseOptions;
    };


    const dateOptions = [
        { label: 'Este mês', value: 'currentmonth' },
        { label: 'Mês anterior', value: 'priormonth' },
        { label: 'Dois meses atrás', value: 'prior2months' },
        { label: 'Período', value: 'period' },
        { label: 'Últimos 3 meses', value: 'last90Days' },
        { label: 'Últimos 6 meses', value: 'last180Days' },
        { label: 'Últimos 12 meses', value: 'last365Days' }
    ];

    const getAssitantData = useCallback(async () => {
        // setAssistantid("");
        if (user && user.ClientGroupSelected) {
            const messageService = new MessageService({});
            var assistantid = await messageService.getAssistantId("75", user.ClientGroupSelected?.toString(), "1");
            setAssistantid(assistantid);
        }
    }, [user])

    const { data: kpi, isLoading: isLoadingKPIs, refetch: refetchReportKPI, isRefetching: isRefetchingKPIs }
    = useQuery<FinOpsDashBoard>(['kpi', refresh], async () => {
        return await retrieveReportKPIs(refresh);
    }, {
        keepPreviousData: true,
        enabled: true,
        staleTime: 7200000,
        onSuccess: () => {
            //console.log('onSuccess');
            setLoading(false);
        },
        optimisticResults: true
    });

    const retrieveCostCenters = useCallback( async() => {
        setCostCenters([]);

        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService({});
            let costCenterResponse = await finOpsMapManagerService.LoadCostCentersByUserId();
            let costCenters = [{ label: '(Todos)', value: '' }, ...costCenterResponse.filter(costcenter => costcenter.active).map(u => {
                return {
                    label: u.name,
                    value: u.id
                };
            })];
            setCostCenters(costCenters);
        } catch (e) {
            console.warn(e)
        }
    },[])

    const retrieveContracts = useCallback( async() => {
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService({});
            var contracts = await finOpsMapManagerService.LoadContracts();

            contracts = contracts.map((u) => {
                return {
                    label: u.contractName,
                    value: u.contractId
                };
            });

            let contractsLocal = [{ label: '(Todos)', value: '' }, ...contracts];
            setContracts(contractsLocal);
        } catch (e) { }
    },[])

    const retrieveData = useCallback(async () => {
        setLoading(true);
        loadingCards[1] = true;
        try {
            const recomendationService = new FinopsRecommendationsService({});

            const results = await Promise.all([
                recomendationService.GetRecommendation(),
                retrieveCostCenters(),
                retrieveContracts(),
                refetchReportKPI()
            ]);
            loadingCards[1] = false;
            loadingCards[2] = false;
            const [response] = results;
            
            const total = response?.reduce((acc, item) => {
                const saving = item.infos?.saving;
                return (saving && saving > 0 )? acc + saving : acc;
            }, 0) || 0;

            setTotalDecimalRecommendation(total);
            setLoadingCards([...loadingCards]);


        } catch (error) {
            console.warn(error);
        } finally {
            loadingCards[1] = false;
            loadingCards[2] = false;
            setLoading(false);
        }
    }, [loadingCards, refetchReportKPI, retrieveContracts, retrieveCostCenters])

    useEffect(() => {
        retrieveData()
        getAssitantData()
    }, [user.ClientGroupSelected, user.refreshFlag]);


    useEffect(() => {
        let coresvalues = Palletes.find(v => v.graphGroup === graphGroupType);
        if (coresvalues) setCores(coresvalues.pallete);
    }, [user.ClientGroupSelected, user.refreshFlag, refresh, graphGroupType]);

    useEffect(() => {
        let coresvalues = Palletes.find(v => v.graphGroup === graphGroupType);
        if (coresvalues) setCores(coresvalues.pallete);
    }, [graphGroupType]);

    useEffect(() => {
        let coresvalues = Palletes.find(v => v.graphGroup === graphType);
        if (coresvalues) setCores(coresvalues.pallete);
    }, [graphType]);

    useEffect(() => {
        getAssitantData();
        setRefresh(user.ClientGroupSelected + tipoDePeriodo + month + year + JSON.stringify(filterConditions) + selectedCostCenter + selectedContractId);
    }, [filterConditions, getAssitantData, month, selectedContractId, selectedCostCenter, tipoDePeriodo, user.ClientGroupSelected, year]);

    const handleChange = (event) => {
        const { name, value } = event;

        // Limpe o gráfico apenas se os dropdowns relacionados ao gráfico "Consumo mensal" forem alterados
        if (name !== 'rankType') {
            chartConsumoLast.current?.getEchartsInstance().clear();
        }

        setFilter({ ...filter, [name]: value });

        if (name === 'rankType')
            setRankType(value);

        if (name === 'graphType')
            setGraphType(value);

        if (name === 'graphGroupType') {
            setGraphGroupType(value);
        }

        if (name === 'date' && value.includes('last')) {
            const updatedOptions = getGraphGroupTypeOptions();
            setGraphGroupType(updatedOptions[1].value);
        }
    }



    async function retrieveReportKPIs(refresh): Promise<FinOpsDashBoard> {
        try {
            let d = moment();
            let currentDay = d.get("date");
            let daysInMonth = d.daysInMonth();
            let daysRemaining = daysInMonth - currentDay;
            setDaysRemaining(daysRemaining);
            const finOpsService = new FinOpsAdvanceFilterService({});
            console.log(selectedCostCenter.toString(), selectedContractId.toString(), graphGroupType, filterConditions)
            let kpisLocal = await finOpsService
                .GetKPIByYearMonthLastPeriod(
                    tipoDePeriodo,
                    selectedCostCenter.toString(),
                    selectedContractId.toString(),
                    graphGroupType,
                    filterConditions,
                    Number(year),
                    Number(month),
                );
                console.log(kpisLocal)
            if (kpisLocal.length > 0) {
                kpisLocal[0].dataGroupbyTotal && kpisLocal[0].dataGroupbyTotal
                    .forEach(rowlast => {
                        rowlast.series = rowlast.series.sort((a: any, b: any) => { return a.date > b.date ? 1 : -1 });
                    });

                if (kpisLocal[0]) {

                    if (kpisLocal[0].dailyConsumption.length === 0 && kpisLocal[0].dailyProviderConsumption.length === 0 && tipoDePeriodo.toLocaleLowerCase() === 'currentMonth') {
                        setConfirmation(true);
                    }
                }
                setLoading(false)
                return kpisLocal[0] as unknown as FinOpsDashBoard;
            }
            // else {
            //     setNoData(true);
            // }
        } catch (e) {

        }
        setLoading(false)
        return {} as FinOpsDashBoard;
    }


    const getConsumptionComparative = (totalConsumption: number, lastMonthTotalConsumption: number) => {
        let last = Number(lastMonthTotalConsumption);
        let actual = Number(totalConsumption);

        let difference = Math.abs(actual - last);

        if (actual > last) {
            let valueDiff = actual ? (difference * 100) / actual : 0;
            valueDiff = valueDiff ? Math.round(valueDiff) : 0; // Arredondando o valor aqui
            return (valueDiff + '% acima');
        }
        else if (actual < last) {
            let valueDiff = last ? (difference * 100) / last : 0;
            valueDiff = valueDiff ? Math.round(valueDiff) : 0; // Arredondando o valor aqui
            return (valueDiff + '% abaixo');
        }
        else {
            return ("igual");
        }
    };

    useEffect(() => {
        async function loadData() {
            console.log('loadDataLoadProcessedAlertApproved')
            loadingCards[2] = true;
            try {
                const finOpsMapManagerService = new FinOpsMapManagerService({});
                var response = await finOpsMapManagerService.LoadProcessedAlertApproved();
                var list = response.alerts;
                var finOpsAlertList = [];

                if (list.filter(x => x.achieved === true).length !== 0) {
                    finOpsAlertList = list.filter(x => x.achieved === true).map(c => {
                        var recordResult = {} as any;
                        try {
                            recordResult.id = c.id;
                            recordResult.alertIcon = c.conditions[0].condition === 'budget' ?
                                'ppi ppi-bell' :
                                c.conditions[0].comparator === 'mais' ?
                                    'ppi ppi-arrow-up' :
                                    'ppi ppi-arrow-down'
                            recordResult.alertClientGroup = { id: response.clientGroupId, description: 'Processor' };
                            recordResult.alertDescription = c.description;
                            recordResult.alertDate = c.date ? c.date : moment().toISOString();

                            recordResult.alertCostCenter = c.costCenters;
                            recordResult.alertContracts = c.contracts;
                            recordResult.alertResources = c.resources;
                            recordResult.alertServices = c.services;

                            recordResult.alertPriorPeriodTotal = c.priorPeriodTotal.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                            recordResult.alertPeriodTotal = c.periodTotal.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 });
                            recordResult.alertAchieved = c.achieved;
                            recordResult.alertDifference = c.difference;
                            recordResult.budgetLimit = c.budgetLimit;
                            if (c.conditions && c.conditions.length > 0) {
                                recordResult.alertVariation = (c.cloudCostEffectiveVariation.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                                recordResult.alertCondition = c.conditions[0].condition === 'variacao' ? ' Variação' : 'N/A';
                                recordResult.alertPercentage = (c.conditions[0] ? '+' : '-') + (c.conditions[0].percentage.toLocaleString('pt-BR', { style: "decimal", maximumFractionDigits: 2 }) + '%');
                                recordResult.alertCompatator = (c.conditions[0].comparator === 'mais' ? 'Mais' : 'Menos');
                            }

                            recordResult.alertPeriod = 'Últimos';

                            try {
                                recordResult.alertPeriodValue = c.period.periodValue;
                            } catch (ec) {
                                console.error(ec);
                            }

                            recordResult.alertPeriodUnit = 'dias';
                            recordResult.alertPeriodStartDate = moment(new Date()).subtract(c.period.periodValue + 2, "days");
                            recordResult.alertPeriodEndDate = moment(new Date()).subtract(2, "days");
                            recordResult.alertScope = c.escope;

                            if (c.conditions[0].condition === 'variacao') {
                                recordResult.alertHistory =
                                {
                                    history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.total }; }),
                                    dataDoAlerta: (c.history && c.history.length > 0 ?
                                        new Date(c.history[0].dia + "/" + c.history[0].mes + "/" + c.history[0].ano)
                                            .toLocaleDateString() : '-')
                                };
                            } else if (c.conditions[0].condition === 'budget') {
                                recordResult.alertHistory =
                                {
                                    history: c.history.map(x => { return { dia: x.dia + "/" + x.mes + "/" + x.ano, valor: x.accTotal }; }),
                                    dataDoAlerta: c.history && c.history.length > 0 ?
                                        new Date(c.history[0].dia + "/" + c.history[0].mes + "/" + c.history[0].ano).toLocaleDateString()
                                        : '-'
                                };
                            }
                        } catch (e) {
                            setLoading(false);
                            console.error(e);
                        }
                        return recordResult;
                    })
                }

                let sortedFinOpsAlertList = finOpsAlertList.sort(
                    (element1: any, element2: any) => {
                        return element1.alertDate < element2.alertDate ? 1 : -1;
                    });

                loadingCards[2] = false;
                setLoadingCards(loadingCards);
                setFinOpsAlertsHistory(sortedFinOpsAlertList);
            } catch (error) {
                console.warn(error);

                loadingCards[2] = false;
                setLoadingCards(loadingCards);
                setFinOpsAlertsHistory([]);
            }
            //setShowFilterPanel(false);

        }
        loadData();
    }, [loadingCards, user.ClientGroupSelected, user.refreshFlag])

    const lineChartOption = (dados: any, max: number = 1, min: number = 1) => {
        return {
            align: 'center',
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                top: '3%',
                height: '100%',
                containLabel: !props.isHome
            },
            xAxis: {
                show: false,
                type: 'category',
                data: dados.map((data: any) => {
                    return data.dia
                })
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [
                {
                    showSymbol: true,
                    symbol: (value, params) => {
                        return params.dataIndex === (dados.length - 1) ? "circle" : "none"
                    },
                    symbolSize: 7,
                    data: dados.map((data: any) => {
                        return data.valor
                    }),
                    type: 'line',
                    silent: true,  // Desativa os eventos do mouse para esta série
                }
            ],
            visualMap:
            {
                show: false,

                inRange: {
                    color: ['#93CE07', '#ff2100']
                },
            }
        }
    }

    //useEffect(() => { setShowFilterPanel(!AIXAgentVisible); }, [AIXAgentVisible])      
    //identificando charts
    const chartPeriodo = useRef<ReactECharts>(null);
    const chartPeriodoHome = useRef<ReactECharts>(null);
    const chartMomento = useRef<ReactECharts>(null);
    const chartAcumulado = useRef<ReactECharts>(null);
    const chartConsumoLast = useRef<ReactECharts>(null);
    const chartConsumoPrevious = useRef<ReactECharts>(null);
    const chartConsumoActual = useRef<ReactECharts>(null);
    const chartAlert = useRef<ReactECharts>(null);

    const formatString = (str: string): string => {
        const semColchetes = str.replace(/^\[|\]$/g, '');
        return semColchetes.charAt(0).toUpperCase() + semColchetes.slice(1);
    };
    const onChagePeriod = (selected: any) => {
        const firstFieldValue = selected.fields[0]?.value;
        const firstFieldName = selected.fields[0]?.name;

        if (firstFieldName === 'month') {
            serPeriodText(formatString(selected.text))
            setYear(firstFieldValue.split('-')[0]);
            setMonth(firstFieldValue.split('-')[1]);
            setTipoDePeriodo(firstFieldName);
        } else {
            serPeriodText(undefined)
            setYear('0');
            setMonth('0');
            setTipoDePeriodo(firstFieldValue);
        }
    };

    useEffect(() => {
        const updatedOptions = getGraphGroupTypeOptions();
        if (graphGroupType === 'resource' && !updatedOptions.some(option => option.value === 'resource')) {
            setGraphGroupType('all');
        }
        setRefresh(user.ClientGroupSelected + tipoDePeriodo + month + year + JSON.stringify(filterConditions) + selectedCostCenter + selectedContractId);
    }, [filterConditions, graphGroupType, month, selectedContractId, selectedCostCenter, tipoDePeriodo, user.ClientGroupSelected, year]);

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentmonth', value: 'currentmonth' }
                ],
                default: !beginOfMonth()
            },
            {
                label: 'Mês anterior', text: 'Mês anterior',
                fields: [
                    { name: 'priormonth', value: 'priormonth' }
                ],
                default: beginOfMonth()
            },
            {
                label: 'Dois meses atrás', text: 'Dois meses atrás',
                fields: [
                    { name: 'prior2months', value: 'prior2months' }
                ],
            },
            {
                label: 'Últimos 3 meses', text: 'Últimos 3 meses',
                fields: [
                    { name: 'last90Days', value: 'last90Days' }
                ]
            },
            {
                label: 'Últimos 6 meses', text: 'Últimos 6 meses',
                fields: [
                    { name: 'last180Days', value: 'last180Days' }
                ]
            },
            {
                label: 'Últimos 12 meses', text: 'Últimos 12 meses',
                fields: [
                    { name: 'last365Days', value: 'last365Days' }
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    //rolagem com card comparativo de 3,6 e 12 meses
    const scrollableRef: RefObject<HTMLDivElement> = useRef(null);
    const handleWheel = (e) => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollLeft += e.deltaY * 0.5;  // Multiplicando por 0.5 para reduzir a velocidade da rolagem
        }
    }

    const positions5Groups = [
        {
            id: 1,//Consumo mensal
            position: { row: 1, col: 1, RowSpan: 2, ColSpan: 2, hidden: false }
        },
        {
            id: 2,//Budgets centro de custo            
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 3,//Comparativo de budgets
            position: { row: 3, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 4,//Top 5
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 5,//Maiores consumos(R$)
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 6,//Projeção mensal
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 7,//Alerta
            position: { row: 5, col: 1, RowSpan: 2, ColSpan: 2, hidden: false }
        },
        {
            id: 8,//Possível economia mensal
            position: { row: 4, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
    ];

    const positions3Groups = [
        {
            id: 1,//Consumo mensal
            position: { row: 1, col: 1, RowSpan: 2, ColSpan: 2, hidden: false }
        },
        {
            id: 2,//Budgets centro de custo
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 3,//Comparativo de budgets || Consumo do mês
            position: { row: 3, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
        {
            id: 4,//Top 5
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 5,//Maiores consumos(R$)
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 6,//Projeção mensal
            position: { row: 5, col: 2, RowSpan: 1, ColSpan: 1, hidden: false }
        },
        {
            id: 7,//Alerta
            position: { row: 5, col: 1, RowSpan: 2, ColSpan: 1, hidden: false }
        },
        {
            id: 8,//Possível economia mensal
            position: { row: 4, col: 1, RowSpan: 1, ColSpan: 2, hidden: false }
        },
    ];

    const positions2Groups = [
        {
            id: 1,//Consumo mensal
            position: { row: 1, col: 1, RowSpan: 2, ColSpan: 1, hidden: false }
        },
        {
            id: 2,//Budgets centro de custo
            position: { row: 1, col: 2, RowSpan: 3, ColSpan: 1, hidden: false }
        },
        {
            id: 3,//Comparativo de budgets || Consumo do mês
            position: { row: 4, col: 1, RowSpan: 1, ColSpan: 1, hidden: false }
        },
        {
            id: 4,//Top 5
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 5,//Maiores consumos(R$)
            position: { row: 1, col: 1, RowSpan: 1, ColSpan: 1, hidden: true }
        },
        {
            id: 6,//Projeção mensal
            position: { row: 5, col: 2, RowSpan: 1, ColSpan: 1, hidden: false }
        },
        {
            id: 7,//Alerta
            position: { row: 5, col: 1, RowSpan: 2, ColSpan: 1, hidden: false }
        },
        {
            id: 8,//Possível economia mensal
            position: { row: 4, col: 2, RowSpan: 1, ColSpan: 1, hidden: false }
        },
    ];

    function positionHome(cardId: number, qtdGroups: number) {
        if (qtdGroups <= 2) {
            return positions2Groups.filter(c => c.id === cardId)[0].position;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter(c => c.id === cardId)[0].position;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter(c => c.id === cardId)[0].position;
        }

        return
    }

    function calculateVariation(current, previous) {
        if (!current || !previous) return { percentVariation: 0, isPositiveVariation: true };  // Trata caso o valor atual ou anterior seja 0 ou undefined
        const variation = ((current - previous) / Math.abs(previous)) * 100;
        return {
            percentVariation: +variation.toFixed(2),  // Retorna o valor em formato decimal
            isPositiveVariation: variation <= 0  // Se for menor ou igual a zero é considerado positivo
        };
    }

    // Função para calcular o consumo total até o dia atual (-1 dia) do mês passado
    function getLastMonthConsumptionUntilToday(data) {
        // Verificar se os dados são válidos
        if (!data || !Array.isArray(data)) return 0;

        const today = new Date();
        const currentDay = today.getDate();

        // Se for o primeiro dia do mês, retorne 0
        if (currentDay === 1) return 0;

        // Filtrar os registros até o dia anterior
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.dateOnly);
            return itemDate.getDate() < currentDay;
        });

        // Somar o consumo desses registros filtrados
        return filteredData.reduce((acc, curr) => acc + curr.consumption, 0);
    }

    let percentVariation: number | null = 0;
    let isPositiveVariation = true;

    const today = new Date();
    const currentDay = today.getDate();

    if (kpi && kpi.lastMonthDailyConsumption && currentDay !== 1) {
        const lastMonthConsumption = getLastMonthConsumptionUntilToday(kpi.lastMonthDailyConsumption);
        const results = calculateVariation(kpi.totalConsumption, lastMonthConsumption);
        percentVariation = results.percentVariation;
        isPositiveVariation = results.isPositiveVariation;
    } else {
        // No primeiro dia do mês ou se os dados de consumo não estiverem disponíveis, defina a variação como null
        percentVariation = null;
    }

    const handleClickCostCenterBudget = () => {
        dispatch({
            type: 'ANALYTICAL_FILTER', payload: {
                id: '',
                costcenter: '',
                typeParam: 'costcenter_budget',
                hasConstCenterChildren: true
            }
        });
        setPageExitConfirmation(true);
    }

    const renderBudgetTooltip = (valueMonth: number, budget: number, monthLabel: string, asChartTooltip: boolean) => {
        const content =
            <div>
                <b>{moment(monthLabel, 'DD/MM/YYYY').format('MMMM')}</b>
                <br />Consumidos <b>{budget ? getSymbolByCurrency("BRL") : 'R$'} {formatDecimal(valueMonth)}</b>
                <br />de um total de <b> {budget ? getSymbolByCurrency("BRL") : 'R$'} {budget ? (budget.toLocaleString('PT-br', { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 })) : 0.00}</b>
            </div>

        return asChartTooltip ? ReactDOMServer.renderToStaticMarkup(content) : content;
    }

    const cards =
        [
            //Consumo mensal
            {
                id: 1,
                type: 'Custom',
                position: { ColSpan: 5, RowSpan: 2, row: 1, col: 1 },
                showFocusButton: !props.isHome,
                infoContent: !props.isHome ? () => (
                    <div className=''>
                        {
                            kpi
                                ?
                                <div className=''>
                                    <span >
                                        {filter?.date && !filter?.date.includes('month') ?
                                            <>
                                                Desde o
                                                {` ${tipoDePeriodo.includes("last") ?
                                                    moment(kpi?.lowestDate).format('D') + `º`
                                                    :
                                                    '1º'
                                                    } `}
                                                dia de

                                            </>
                                            :
                                            <>
                                                No mês de
                                            </>
                                        }
                                        <b style={{ textTransform: "capitalize" }}>
                                            {` ${tipoDePeriodo.includes('current') ?
                                                moment().format('MMMM')
                                                :
                                                moment(kpi?.lowestDate).format('MMMM')
                                                }
                                                `}
                                        </b>
                                        , estimadamente, foram consumidos
                                        <b>{` ${kpi.contractCurrencyLabel ? getSymbolByCurrency("BRL") : 'R$'}
                                            ${kpi.totalConsumption && formatDecimal(kpi.totalConsumption)}
                                        `}</b>
                                        com serviços e impostos inclusos.
                                    </span>
                                </div>
                                :
                                <LcNoData size="small" />
                        }
                    </div>
                ) : undefined,
                hideHeader: props.isHome,
                title: () => {
                    return <>
                        {
                            tipoDePeriodo.includes("month") && <div className={'lc-segment-title-row-resume'} >
                                <div className={'lc-title ellipsis'}>
                                    Consumo mensal ({kpi ? getSymbolByCurrency("BRL") : 'R$'})
                                </div>
                                <PPDropDown
                                    name='graphType'
                                    options={graphOptions}
                                    onChange={(event) => handleChange({ ...event, name: 'graphType' })}
                                    defaultValue={graphOptions.find(option => option.value === graphType)}
                                    value={graphOptions.find(option => option.value === graphType)}
                                    width='161px'
                                />

                            </div>
                        }
                        {
                            tipoDePeriodo.includes("last") && <div className={'lc-segment-title-row-resume'} >
                                <div className={'lc-title ellipsis'}>
                                    Consumo mensal ({kpi ? getSymbolByCurrency("BRL") : 'R$'})
                                </div>
                                <PPDropDown
                                    name='graphGroupType'
                                    options={getGraphGroupTypeOptions()}
                                    onChange={(event) => handleChange({ ...event, name: 'graphGroupType' })}
                                    value={getGraphGroupTypeOptions().find(option => option.value === graphGroupType)}
                                    defaultValue={getGraphGroupTypeOptions().find(option => option.value === graphGroupType)}
                                    width='159px'
                                />
                            </div>
                        }
                    </>
                },
                customContentRender: (focused) => {
                    return <LcLoading loading={isLoadingKPIs || isRefetchingKPIs || loading}>
                        {tipoDePeriodo.includes("month") &&
                            <>
                                {!(isLoadingKPIs || isRefetchingKPIs) && kpi && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption && (
                                    props.isHome ? (
                                        <div className="chart-container">
                                            <div className="chart-content">
                                                <ReactECharts key={graphType}
                                                    ref={chartPeriodoHome}
                                                    style={{ width: '100%', height: '100%' }}
                                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                    option={finOpsChartService.getLineConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, false, kpi, true, `Consumo em ${moment(today).format('MMMM')}`)}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="chart-container">
                                            <div className="chart-content">
                                                {graphType === '1' &&
                                                    <ReactECharts key={graphType}
                                                        ref={chartPeriodo}
                                                        style={{ width: '100%', height: '100%' }}
                                                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                        option={finOpsChartService.getLineConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, false, kpi)}
                                                    />
                                                }
                                                {graphType === '2' &&
                                                    <ReactECharts key={graphType}
                                                        ref={chartMomento}
                                                        style={{ width: '100%', height: '100%' }}
                                                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                        option={finOpsChartService.getLineConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, kpi)}
                                                    />
                                                }
                                                {graphType === '3' &&
                                                    <ReactECharts key={graphType}
                                                        ref={chartAcumulado}
                                                        style={{ width: '100%', height: '100%' }}
                                                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                        option={finOpsChartService.getAreaConsumptionChart(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, kpi)}
                                                    />
                                                }
                                                {(graphType !== '3' && graphType !== '2' && graphType !== '1') &&
                                                    <>
                                                        <div className={'lc-segment-buttons'}>
                                                            <div className="lc-buttons">
                                                                <button title={""} className={`pp-button small ${chartType === 'stack' ? 'active' : ''}`} type='button' onClick={() => setChartType('stack')} ><i className="ppi ppi-view-boards ppi-2x" /></button>
                                                                {
                                                                    (kpi && kpi.dataGroupbyTotal && kpi.dataGroupbyTotal.length > 1) && <button title={""} className={`pp-button small ${chartType === 'bar' ? 'active' : ''}`} type='button' onClick={() => setChartType('bar')} ><i className="ppi ppi-chart-bar ppi-2x" /></button>
                                                                }
                                                                <button title={""} className={`pp-button small ${chartType === 'line' ? 'active' : ''}`} type='button' onClick={() => setChartType('line')} ><i className="ppi ppi-trending-up ppi-2x" /></button>
                                                            </div>
                                                        </div>
                                                        <div className="chart-container">
                                                            <div className="chart-content">
                                                                <ReactECharts key={`${graphType}-${chartType}`}
                                                                    ref={chartConsumoLast}
                                                                    style={{ width: '100%', height: '100%' }}
                                                                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                                    option={finOpsChartService
                                                                        .getBarConsumptionChart(
                                                                            tipoDePeriodo, cores, kpi,
                                                                            chartType as any, focused, graphType)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                )}
                                {!(kpi && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption) &&
                                    <LcNoData size="small" />
                                }
                            </>
                        }
                        {
                            tipoDePeriodo.includes("last") &&
                            <>
                                {!(isLoadingKPIs || isRefetchingKPIs) && kpi && kpi?.dataGroupbyTotal &&
                                    <>
                                        <div className={'lc-segment-buttons'}>
                                            <div className="lc-buttons">
                                                <button title={""} className={`pp-button small ${chartType === 'stack' ? 'active' : ''}`} type='button' onClick={() => setChartType('stack')} ><i className="ppi ppi-view-boards ppi-2x" /></button>
                                                {
                                                    (kpi && kpi.dataGroupbyTotal && kpi.dataGroupbyTotal.length > 1) &&
                                                    <button title={""} className={`pp-button small ${chartType === 'bar' ? 'active' : ''}`} type='button' onClick={() => setChartType('bar')} ><i className="ppi ppi-chart-bar ppi-2x" /></button>
                                                }<button title={""} className={`pp-button small ${chartType === 'line' ? 'active' : ''}`} type='button' onClick={() => setChartType('line')} ><i className="ppi ppi-trending-up ppi-2x" /></button>
                                            </div>
                                        </div>
                                        <div className="chart-container">
                                            <div className="chart-content">
                                                <ReactECharts key={`${graphType}-${chartType}-${tipoDePeriodo}`}
                                                    ref={chartConsumoLast}
                                                    style={{ width: '100%', height: '100%' }}
                                                    opts={{ renderer: 'canvas', locale: 'PT-br' }}
                                                    option={finOpsChartService
                                                        .getBarConsumptionChart(
                                                            tipoDePeriodo, cores, kpi,
                                                            chartType as any, focused, graphGroupType)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {kpi === undefined &&
                                    <LcNoData size="small" />
                                }
                            </>
                        }
                    </LcLoading>
                }
            },
            //Budgets centro de custo
            {
                id: 2,
                ticketButtonLabel: !props.isHome ? "Ir para centros de custos" : undefined,
                ticketButton: !props.isHome ? () => {
                    return <PiArrowSquareOutLight onClick={() => { handleClickCostCenterBudget(); }} />;
                } : undefined,
                title: () => {
                    return <div className="lc-title ellipsis">Budgets centros de custos</div>
                },
                type: 'Custom',
                position: { ColSpan: 3, RowSpan: 2, row: 1, col: 6 },
                customContentRender: () => {
                    return (
                        <CostCenterBudgetChart
                            loadingCards={loadingCards}
                            isLoading={isLoadingKPIs || isRefetchingKPIs}
                            kpi={kpi}
                            setPageExitConfirmation={setPageExitConfirmation}
                        />
                    );
                }
            },
            //Comparativo Budgets 
            {
                id: 3,
                title: () => {
                    return props.isHome ? kpi && <>Consumo em {moment(kpi.lowestDate).format('MMMM')}</> : <>Comparativo budgets</>;
                },
                type: 'Custom',
                position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9 },
                infoContent: !props.isHome ? () => {
                    if (tipoDePeriodo.includes("month")) {
                        return (
                            <>
                                {kpi &&
                                    <>
                                        {kpi?.totalConsumption
                                            && kpi?.lastMonthTotalConsumption
                                            && kpi?.totalConsumption > 0 && kpi?.lastMonthTotalConsumption > 0
                                            && <span>
                                                {`Em `}
                                                {filter?.date && filter?.date === dateOptions[0].value ?
                                                    `${moment().format('MMMM')} `
                                                    :
                                                    `${moment(kpi.lowestDate).format('MMMM')} `
                                                }
                                                {
                                                    (moment(kpi?.lowestDate).month() === moment().month()) &&
                                                    <>
                                                        , faltando
                                                        <b>{` ${daysRemaining} `}</b>
                                                        dias para o final do mês,
                                                    </>
                                                }
                                                {` o consumo total está `}
                                                <b>
                                                    {kpi?.totalConsumption && kpi?.lastMonthTotalConsumption && kpi?.totalConsumption > 0 && kpi?.lastMonthTotalConsumption > 0 ? `${getConsumptionComparative(kpi?.totalConsumption, kpi?.lastMonthTotalConsumption)}` : ''}
                                                </b>
                                                {` ${kpi?.totalConsumption === kpi?.lastMonthTotalConsumption && kpi?.lastMonthDailyConsumption ? `ao` : `do`} consumo de ${moment(kpi?.lastMonthDailyConsumption[0].label, 'DD/MM/YYYY').format('MMMM')}, passando de `}
                                                <b>
                                                    {`${kpi ? getSymbolByCurrency("BRL") : 'R$'}  ${kpi?.lastMonthTotalConsumption && formatDecimal(kpi?.lastMonthTotalConsumption)}`}
                                                </b>
                                                {` para `}
                                                <b>
                                                    {`${kpi ? getSymbolByCurrency("BRL") : 'R$'}  ${kpi?.totalConsumption && formatDecimal(kpi?.totalConsumption)}`}
                                                </b>
                                                {`. Este valor representa `}
                                                <b>
                                                    {`${Math.round((kpi?.totalConsumption * 100) / (kpi?.baseline || 1))}%`}
                                                </b>
                                                {` do budget mensal.`}
                                            </span>
                                        }
                                    </>
                                }
                            </>
                        );
                    } else {
                        return null;
                    }
                } : undefined,
                customContentRender: () => {
                    if (props.isHome) {
                        return <LcLoading loading={isLoadingKPIs || isRefetchingKPIs}>
                            {tipoDePeriodo.includes("month") && <>
                                {kpi === undefined ?
                                    <LcNoData size="small" />
                                    :
                                    <div className="consumo-mes">
                                        {kpi && kpi?.dailyConsumption && kpi?.dailyConsumption.length > 0 &&
                                            <div className='consumo'>
                                                <div className="value">
                                                    {kpi && kpi.totalConsumption > 0 &&
                                                        <LcTooltip content={<Currency amount={kpi.totalConsumption} />} top trigger="hover" position="left" >
                                                            <CurrencyWithKLC value={kpi.totalConsumption} displayCurrencySymbol={false} displayDecimals={false} />
                                                        </LcTooltip>
                                                    }
                                                </div>
                                                {percentVariation !== null && percentVariation !== 0 && (
                                                    <div className="variation" style={{ backgroundColor: isPositiveVariation ? '#63bdc7' : '#D35555' }}>
                                                        <LcTooltip content={isPositiveVariation ? 'menor que o mês anterior' : 'maior que o mês anterior'} top trigger="hover" position="right">
                                                            {percentVariation}%
                                                        </LcTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        }

                                        <div className='budget'>
                                            <LcTooltip content={<Currency amount={kpi.baseline} />} top trigger="hover" position="left" >
                                                <div className="value">
                                                    <CurrencyWithKLC value={kpi.baseline} displayCurrencySymbol={true} displayDecimals={false} />
                                                </div>
                                            </LcTooltip>
                                            <div className="label">Budget informado</div>

                                        </div>

                                    </div>
                                }
                            </>
                            }
                        </LcLoading>
                    } else {
                        var budgetCurrent = 0.00;
                        var budgetPrevious = 0.00;
                        try {
                            var dataCostCenterBudgetCurrent = kpi && kpi?.baselineHistory && kpi?.baselineHistory[0].monthBudget ? kpi?.baselineHistory[0].monthBudget.filter(v => v.month === moment(kpi?.dailyConsumption[0]?.label, 'DD/MM/YYYY').format('YYYY-MM')) : [] as unknown as MonthBudget[];
                            var dataCostCenterBudgetPrevious = kpi && kpi?.baselineHistory && kpi?.baselineHistory[0].monthBudget ? kpi?.baselineHistory[0].monthBudget.filter(v => v.month === moment(kpi?.lastMonthDailyConsumption[0]?.label, 'DD/MM/YYYY').format('YYYY-MM')) : [] as unknown as MonthBudget[];
                            if (kpi && kpi?.baselineHistory) {

                                dataCostCenterBudgetCurrent = finOpsChartService.getRecordWithMaxMonthAfterDate(kpi?.baselineHistory[0].monthBudget, moment(kpi?.dailyConsumption[0]?.label, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                budgetCurrent = dataCostCenterBudgetCurrent && dataCostCenterBudgetCurrent.length > 0 ? dataCostCenterBudgetCurrent[0].value : 0.00;
                                dataCostCenterBudgetPrevious = finOpsChartService.getRecordWithMaxMonthAfterDate(kpi?.baselineHistory[0].monthBudget, moment(kpi?.lastMonthDailyConsumption[0]?.label, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                budgetPrevious = dataCostCenterBudgetPrevious && dataCostCenterBudgetPrevious.length > 0 ? dataCostCenterBudgetPrevious[0].value : 0.00;
                            }

                            if (budgetPrevious === 0.00) {
                                budgetPrevious = kpi?.baseline ? kpi?.baseline : 0.00;
                            }

                            if (budgetCurrent === 0.00) {
                                budgetCurrent = budgetPrevious;
                                if (budgetCurrent === 0.00) {
                                    budgetCurrent = kpi?.baseline ? kpi?.baseline : 0.00;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }

                        return <LcLoading loading={isLoadingKPIs || isRefetchingKPIs}>
                            {tipoDePeriodo.includes("month") && <>
                                {kpi === undefined ?
                                    <LcNoData size="small" />
                                    :
                                    <div className="comparativo-container">
                                        {!(isLoadingKPIs || isRefetchingKPIs) && kpi && kpi.lastMonthTotalConsumption != null &&
                                            kpi?.lastMonthDailyConsumption && kpi?.lastMonthDailyConsumption.length > 0 &&
                                            <div className='comparativo-content'>
                                                <div className='row-title'>
                                                    <span><b>{moment(kpi?.lastMonthDailyConsumption[0].label, 'DD/MM/YYYY').format('MMMM')}</b></span>
                                                </div>
                                                <div className='row-content chart-container'>
                                                    <div className="chart-container">
                                                        <div className="chart-content">
                                                            <ReactECharts
                                                                ref={chartConsumoPrevious}
                                                                style={{ width: '100%', height: '100%' }}
                                                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                                option={finOpsChartService.getBudgetChart(false, kpi.lastMonthTotalConsumption, budgetPrevious, kpi?.lastMonthDailyConsumption[0].label, renderBudgetTooltip)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row-subtitle text-element'>
                                                        {kpi && kpi.lastMonthTotalConsumption > 0 && <Currency amount={kpi.lastMonthTotalConsumption} />}
                                                    </div>
                                                    <Tooltip arrow title={renderBudgetTooltip(kpi.lastMonthTotalConsumption, budgetPrevious, kpi?.lastMonthDailyConsumption[0].label, false)}>
                                                        <div className='row-subsubtitle text-element'>
                                                            {budgetPrevious && <Currency amount={budgetPrevious} />}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }
                                        {kpi && kpi?.dailyConsumption && kpi?.dailyConsumption.length > 0 &&
                                            <div className='comparativo-content'>
                                                <div className='row-title'>
                                                    <span><b>{moment(kpi?.dailyConsumption[0].label, 'DD/MM/YYYY').format('MMMM')}</b></span>
                                                </div>
                                                <div className='row-content chart-container'>
                                                    <div className="chart-container">
                                                        <div className="chart-content">
                                                            {kpi && <ReactECharts
                                                                ref={chartConsumoActual}
                                                                showLoading={loading}
                                                                style={{ width: '100%', height: '100%' }}
                                                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                                option={finOpsChartService.getBudgetChart(true, kpi?.totalConsumption, budgetCurrent, kpi?.dailyConsumption[0].label, renderBudgetTooltip)}
                                                            />}
                                                        </div>
                                                    </div>
                                                    <div className='row-subtitle text-element' style={{ color: 'var(--color-primary-purple-light)' }}>
                                                        {kpi && kpi.totalConsumption > 0 && <Currency amount={kpi.totalConsumption} />}
                                                    </div>
                                                    <Tooltip arrow title={renderBudgetTooltip(kpi?.totalConsumption, budgetCurrent, kpi?.dailyConsumption[0].label, false)}>
                                                        <div className='row-subsubtitle text-element'>
                                                            {budgetCurrent && <Currency amount={budgetCurrent} />}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>}
                                    </div>
                                }
                            </>
                            }
                            {
                                tipoDePeriodo.includes("last") &&
                                <div className="comparativo-container last scrollable-h" style={{ overflowY: "hidden" }} onWheel={handleWheel} ref={scrollableRef}>
                                    {kpi?.dataGroupbyTotal && kpi?.dataGroupbyTotal[0].series.map((item: any, index: number) => {
                                        var budgetCurrentLast = 0.00;
                                        try {
                                            var dataCostCenterBudgetCurrent = kpi && kpi?.baselineHistory && kpi?.baselineHistory[0].monthBudget ?
                                                kpi?.baselineHistory[0].monthBudget.filter(v => v.month === moment(item.date.split('T')[0], 'YYYY-MM-DD').format('YYYY-MM'))
                                                : [] as unknown as MonthBudget[];

                                            if (kpi && kpi?.baselineHistory) {
                                                dataCostCenterBudgetCurrent = finOpsChartService.getRecordWithMaxMonthAfterDate(kpi?.baselineHistory[0].monthBudget, moment(item.date.split('T')[0], 'YYYY-MM-DD').format('YYYY-MM'));
                                                budgetCurrentLast = dataCostCenterBudgetCurrent && dataCostCenterBudgetCurrent.length > 0 ? dataCostCenterBudgetCurrent[0].value : 0.00;
                                            }

                                            if (budgetCurrentLast === 0.00) {
                                                budgetCurrentLast = kpi?.baseline ? kpi?.baseline : 0.00;
                                            }

                                        } catch (e) {
                                            console.error(e);
                                        }

                                        return (
                                            <div className='comparativo-content' key={'comparativo-content' + index}>
                                                <div className='row-title' key={'row-title' + index}>
                                                    <span><b>{moment(item.date.split('T')[0], 'YYYY-MM-DD').format('MMM/YYYY')}</b></span>
                                                </div>
                                                <div className='row-content chart-container' key={'row-content-chart-container' + index}>
                                                    <div className="chart-container" key={'chart-container' + index}>
                                                        <div className="chart-content" key={'chart-content' + index}>
                                                            {kpi && <ReactECharts
                                                                ref={chartConsumoActual}
                                                                showLoading={loading}
                                                                style={{ width: '100%', height: '100%' }}
                                                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                                                option={finOpsChartService.getBudgetLastPeriodsChart(item?.total, kpi, moment(item.date.split('T')[0], 'YYYY-MM-DD').format('MMM/YYYY'), budgetCurrentLast)}
                                                            />}
                                                        </div>
                                                    </div>
                                                    <div className='row-subtitle text-element' key={'row-subtitle-text-element' + index} style={{ color: 'var(--color-primary-purple-light)' }}>
                                                        {kpi && item?.total > 0 && <Currency amount={item?.total} />}
                                                    </div>
                                                    <div className='row-subsubtitle text-element' key={'row-subsubtitle-text-element' + index}>
                                                        {budgetCurrentLast && <Currency amount={budgetCurrentLast} />}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </LcLoading >
                    }
                }
            },
            //Top 5
            {
                id: 4,
                type: 'Custom',
                showFocusButton: !props.isHome,
                position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1 },
                title: () => {
                    return <>
                        {
                            <div className={'lc-segment-title-row-resume'}>
                                <div className={'lc-title ellipsis'}>
                                    {`Top consumos(${kpi ? getSymbolByCurrency("BRL") : 'R$'})`}
                                </div>
                                <PPDropDown
                                    name='rankType'
                                    options={rankTypeOptions}
                                    defaultValue={rankTypeOptions.find(option => option.value === rankType)}
                                    value={rankTypeOptions.find(option => option.value === rankType)}
                                    onChange={(event) => handleChange({ ...event, name: 'rankType' })}
                                    width='120px'
                                />
                            </div>
                        }</>
                },
                customContentRender: () => {
                    return <TopConsumptionChart 
                            kpi={kpi}
                            loading={isLoadingKPIs || isRefetchingKPIs || loading}
                            rankType={rankType}
                            rankTypeOption={rankTypeOptions.find((option) => option.value === rankType)?.label}
                    /> 
                }
            },
            // Maiores consumos(R$)
            {
                id: 5,
                type: 'Custom',
                position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4 },
                showFocusButton: true,
                customContentRender: (focused?: boolean) => {
                    return <FinOpsNestedCostCenterCosts
                        costCenters={costCenters}
                        contracts={contracts}
                        selectedContract={selectedContractId}
                        selectedCostCenter={selectedCostCenter}
                        tipoDePeriodo={tipoDePeriodo}
                        filter={filterConditions}
                        focused={focused}
                        ConfimationModal={setPageExitConfirmation}
                        month={month}
                        periodText={periordText}
                        year={year}
                    />
                }
            },
            //Projeção mensal
            {
                id: 6,
                title: () => {
                    const isProjection = tipoDePeriodo === "currentmonth";
                    const titleText = !tipoDePeriodo.includes('last') ? isProjection ? "Projeção mensal" : "Comparado ao anterior" : "Média dos meses";
                    return <div className="lc-title ellipsis" title={titleText}>{titleText}</div>
                },
                type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 2, col: 9 },
                customContentRender: () => {
                    let isAbove = false;
                    if (kpi && kpi?.baseline > 0 && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption) {
                        const projectedConsumption = finOpsChartService.getProjectoMax(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, tipoDePeriodo.includes('last'));
                        // const baseline = kpi?.lastMonthTotalConsumption ? kpi?.lastMonthTotalConsumption : 1;
                        isAbove = (projectedConsumption / kpi?.lastMonthTotalConsumption) > 1;
                        isAbove = !isAbove;
                    }

                    const arrowIcon = isAbove ? <PiArrowDownLight /> : <PiArrowUpLight />;
                    const colorClass = isAbove ? 'green' : 'red';

                    const percentageValue = Math.abs(
                        (kpi && kpi?.baseline > 0 && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption
                            ? (finOpsChartService.getProjectoMax(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, tipoDePeriodo.includes('last'))
                                / (kpi?.lastMonthTotalConsumption ? kpi?.lastMonthTotalConsumption : 1))
                            : 1
                        ) - 1
                    );

                    const subtitleTitle = `${(percentageValue * 100).toFixed(2)}% ${isAbove ? 'abaixo' : 'acima'} do consumo mês anterior`;

                    return (
                        <LcLoading loading={isLoadingKPIs || isRefetchingKPIs}>
                            {(kpi && kpi?.baseline > 0 && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption) && <div className='projecao-container'>
                                <div className={'projecao-content r-align'}>
                                    <div className={`icon ${colorClass}`}>
                                        {!tipoDePeriodo.includes('last') ? arrowIcon : ''}
                                    </div>
                                    <div className={`value ${colorClass}`}>
                                        {
                                            kpi
                                                ? kpi.totalConsumption
                                                    ? <CurrencyWithKLC value={kpi && kpi?.baseline > 0 && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption ? finOpsChartService.getProjectoMax(kpi?.dailyConsumption, kpi?.lastMonthDailyConsumption, true, tipoDePeriodo.includes('last')) : 0.00} />
                                                    : 0.00
                                                : 0.00
                                        }
                                    </div>
                                </div>
                                {
                                    kpi?.lastMonthTotalConsumption && kpi?.lastMonthTotalConsumption > 0 ?
                                        <div className='subtitle-dash ellipsis' title={subtitleTitle}>
                                            <PercentComponent number={percentageValue} /> {isAbove ? 'abaixo' : 'acima'} do consumo mês anterior
                                        </div> : ''
                                }
                            </div>}
                            {!(kpi && kpi?.baseline > 0 && kpi?.dailyConsumption && kpi?.lastMonthDailyConsumption) &&
                                <LcNoData size="small" label="Sem dados no período." />
                            }
                        </LcLoading>
                    );
                }
            },
            //Alerta
            {
                id: 7,
                ticketButtonLabel: !props.isHome ? "Ir para alertas" : undefined,
                ticketButton: !props.isHome ? () => {
                    return <PiArrowSquareOutLight onClick={() => { history.push("/finops/alertasNotificacao") }} />;
                } : undefined,
                title: () => {
                    return (
                        <div className="lc-title ellipsis">
                            {props.isHome ? "Alerta de consumo" : "Alerta"}
                        </div>
                    );
                },
                type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 2, col: 11 },
                customContentRender: () => {
                    const hasAlerts = finOpsAlertsHistory?.length > 0;
                    const alertDescription = hasAlerts ? finOpsAlertsHistory[0].alertDescription : '';

                    // Fixed check to ensure that all properties exist and 'history' is an array
                    const chartOptions = hasAlerts && finOpsAlertsHistory[0].alertHistory && Array.isArray(finOpsAlertsHistory[0].alertHistory.history)
                        ? lineChartOption(finOpsAlertsHistory[0].alertHistory.history, 1, 0)
                        : [];

                    return (
                        <LcLoading loading={loadingCards[2] || loading}>
                            {hasAlerts ? (
                                <div className='alerta-container w-100p' style={{ placeContent: 'center', height: "100%", width: '100%' }}>
                                    <div className='alerta-content' style={{ alignItems: 'center', height: "50%", width: '100%' }}>
                                        <div className='icon' style={{ visibility: 'visible' }}>
                                            <PiWarningLight size={props.isHome ? 24 : 32} />
                                        </div>
                                        <ReactECharts
                                            ref={chartAlert}
                                            lazyUpdate={true}
                                            opts={{ renderer: 'canvas', locale: 'PT-br' }}
                                            style={{ height: "100%", width: '100%' }}
                                            option={chartOptions}
                                        />
                                    </div>
                                    <div className='subtitle-dash ellipsis' title={alertDescription}>
                                        {alertDescription}
                                    </div>
                                </div>
                            ) : (
                                <LcNoData size="small" label="Sem dados no período." />
                            )}
                        </LcLoading>
                    );
                }
            },
            //Possível economia mensal
            {
                id: 8,
                bgColor: 'var(--color-primary-purple-light)',
                fontColor: '#FFFFFF',
                hideHeader: props.isHome,
                title: () => { return <div style={{ color: 'white' }} className="lc-title ellipsis">Possível economia mensal</div> },
                type: 'Custom', position: { ColSpan: 4, RowSpan: 1, row: 1, col: 9 },
                customContentRender: () => {
                    return <LcLoading loading={loadingCards[2]}>
                        <div className='card-big-number colorfull'>
                            <div className="content">
                                <PiPiggyBankLight size={props.isHome ? 24 : 32} />
                                <div style={{ color: 'white' }} className='otimizacoes-text'>
                                    <CurrencyWithKLC value={totalDecimalRecommendation} /></div>
                            </div>
                            <div
                                style={{ color: 'white' }}
                                className={`subtitle-dash ellipsis ${props.isHome ? 'subtitle-dash-ishome' : ''}`}
                            >
                                {props.isHome ? 'Possível economia mensal' : 'Alguns recursos podem ser redimensionados'}
                            </div>

                        </div>
                    </LcLoading>
                }
            }
        ]

        useEffect(() => {
        if (showFilterPanel === true) {
            setAIXAgentVisible(false);
        }
    }, [showFilterPanel])

    useEffect(() => {
        if (aixAgentVisible === true) {
            setShowFilterPanel(false);
        }
    }, [aixAgentVisible])

    var assitantToolBaricon = [{
        icon: <LcIconLink icon='ppi ppi-lightning-bolt'
            id='aiLCChamados'
            size='small'
            degrade
            tooltip={"FinOps AI"}
            tooltipPosition='right' />,
        onClick: () => {
            setAIXAgentVisible(!aixAgentVisible)
        }, tooltip: `FinOps AI`
    }];
    var toolbarFunctions = [
        {
            icon: <PiInfoLight />,
            tooltip: () => {
                return <div className="super-info">
                    <span className='font-4x text-center text-primary'>
                        {`Exibindo dados do contrato `}
                        <b>
                            {`${kpi?.contractName}`}
                        </b>
                        {`. Valores estimados baseados em baseline mensal `}
                        <em>pro rata</em>
                        {` para dia de uso da solução. Consumo calculado sobre a estimativa média diária relativa ao baseline de `}
                        <b>
                            {`${kpi ? getSymbolByCurrency("BRL") : 'R$'} ${kpi?.baseline && formatDecimal(kpi?.baseline)}`}
                        </b>
                        {` mensais. Atualizado até `}
                        <b>{`${moment(kpi?.highestDate).format('LLL')}`}</b>
                        {`.`}
                        {` Consumos apurados em dólar serão atualizados no dia do faturamento.`}
                    </span>
                </div>
            },
            onClick: () => null
        },
        {
            icon: filtered ? <PiFunnelDuotone size='small' /> : <PiFunnelLight />,
            tooltip: "Filtro",
            color: filtered ? 'var(--color-secondary-lime)' : 'inherit',
            onClick: () => {
                setShowFilterPanel(!showFilterPanel);
            },
            //clickble: showFilterPanel
        }
    ];


    return (
        props.isHome ?
            (
                <LCDashboard isHomeType grid={[6, 2]} cards={props.qtdGroups && props.qtdGroups > 0 ? cards.map((card, index) => {
                    if (props.qtdGroups) {
                        var posi = positionHome(card.id, props.qtdGroups);
                        if (posi !== undefined) {
                            card.position = posi;
                        }
                    }
                    return card;
                }) : []} />
            ) :
            (
                <Layout pageTitle='FinOps'
                    loading={loading && isLoadingKPIs && isRefetchingKPIs}
                    periodSelection={periodSelection}
                    functionsGeneric={assistantid && assistantid.length > 0 ? [...toolbarFunctions, ...assitantToolBaricon] : toolbarFunctions}
                >
                    {cards && <LCDashboard
                        cards={cards}
                        rightSidePanel={{
                            title: aixAgentVisible && !showFilterPanel ? 'LiveCloud AI' : 'Filtros',
                            titleClass: aixAgentVisible && !showFilterPanel ? 'ai-gradient-text' : undefined,
                            focusable: aixAgentVisible && !showFilterPanel,
                            pinned: false,
                            show: Boolean(Number(showFilterPanel) + Number(aixAgentVisible)),
                            close: (filterPanelShow) => { setShowFilterPanel(filterPanelShow); setAIXAgentVisible(filterPanelShow); },
                            content: () => {
                                return <>{!aixAgentVisible && showFilterPanel ? <LcLoading loading={(loading)} label='Carregando Filtros...'>
                                    <div className="right-sidepanel-filters">
                                        <div className="principal-filters">
                                            <PPDropDown key={'contracts'}
                                                title='Contrato'
                                                name='contracts'
                                                options={contracts}
                                                defaultValue={contracts.find(option => option.value === selectedContractIdAux)}
                                                value={contracts.find(option => option.value === selectedContractIdAux) ? contracts.find(option => option.value === selectedContractIdAux) : ''}
                                                onChange={event => {
                                                    setSelectedContractIdAux(event.value);
                                                }}
                                                width='100%'
                                            />
                                            {
                                                costCenters &&
                                                <PPDropDown key={'idCostCenter'}
                                                    title="Centro de custo"
                                                    name="idCostCenter"
                                                    options={costCenters}
                                                    defaultValue={costCenters.find(option => option.value === selectedCostCenterAux)}
                                                    value={costCenters.find(option => option.value === selectedCostCenterAux) ? costCenters.find(option => option.value === selectedCostCenterAux) : ''}
                                                    onChange={event => {
                                                        setSelectedCostCenterAux(event.value);
                                                    }}
                                                    width='100%'
                                                />
                                            }</div>
                                        <div>
                                            <ConditionFilterResume
                                                visible={true}
                                                conditions={filterConditions.conditions}
                                                onSubmit={data => {
                                                    setFiltered(true)
                                                    setFilterConditions({ ...{ ...filterConditions, conditions: data } });
                                                    setSelectedContractId(selectedContractIdAux);
                                                    setSelectedCostCenter(selectedCostCenterAux);
                                                    setRefresh(user.ClientGroupSelected + tipoDePeriodo + month + year + JSON.stringify({ ...{ ...filterConditions, conditions: data } }) + selectedCostCenter + selectedContractId + "." + Math.random());

                                                }}
                                                onClose={() => { setShowFilterPanel(false); setAIXAgentVisible(false); }}
                                                onClear={() => {
                                                    setFiltered(false)
                                                    setSelectedContractId('');
                                                    setSelectedCostCenter('');
                                                    setSelectedContractIdAux('');
                                                    setSelectedCostCenterAux('');
                                                    setFilterConditions({ ...{ ...filterConditions, conditions: defaultFilter.conditions } });
                                                    setRefresh(user.ClientGroupSelected + tipoDePeriodo + month + year + JSON.stringify({ ...{ ...filterConditions, conditions: defaultFilter.conditions } }) + '_');

                                                }}
                                            /></div>
                                    </div>
                                </LcLoading> : aixAgentVisible && !showFilterPanel &&
                                <div style={{ alignSelf: 'center' }}>
                                    <AIxChatComponent eventChat={(_event) => { }} assistantId={assistantid} assistantName={"FinOps"} />
                                </div>
                                }
                                </>;
                            }
                        }}
                    />}
                    <Alert
                        visible={confirmation}
                        text={'O provedor ainda não disponibilizou dados financeiros para o mês atual, por isso vamos exibir dados do mês anterior.'}
                        context={'info'}
                        title={'Atenção'}
                        onClose={() => {
                            setTipoDePeriodo('priormonth');
                            setFilter({ ...filter, date: 'priormonth' });
                            setConfirmation(false)
                        }}
                    />
                    <Confirmation
                        display={pageExitConfirmation}
                        title='Tem certeza que deseja sair?'
                        text={'Ao clicar em "Continuar", você será redirecionado para outra página. Tem certeza de que deseja prosseguir?'}
                        confirm={() => history.push('Analitico')}
                        textBtnOk='Continuar'
                        close={() => setPageExitConfirmation(false)}
                    />
                </Layout >
            )
    );
};

export default FinOpsResume;