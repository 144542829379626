import api, { APIClient } from "./api";


export interface Question {
    id: string,
    name: string,
    text: string,
    minimum: number,
    maximum: number,
    lessText: string,
    moreText: string,
    showReason: boolean,
    active: boolean,
    owner: string,
    periodType: number,
    periods: number,
    triggerAlertBelow?: number
}
export interface Answer {
    id?: string,
    question: string,
    user: string,
    clientGroup: number,
    when?: Date,
    note?: number,
    reason?: string
}
export interface AnswerView {
    id: string,
    question: string,
    user: string,
    clientGroup: string,
    when: Date,
    note?: number
    reason?: string
}

export class NpsService extends APIClient {

    //     ___                  _   _                 
    //    / _ \ _   _  ___  ___| |_(_) ___  _ __  ___ 
    //   | | | | | | |/ _ \/ __| __| |/ _ \| '_ \/ __|
    //   | |_| | |_| |  __/\__ \ |_| | (_) | | | \__ \
    //    \__\_\\__,_|\___||___/\__|_|\___/|_| |_|___/
    //                                                

    async GetQuestions() {
        const url = this.endpoint + `/api/Nps/Questions`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetQuestion(id: string) {
        const url = this.endpoint + `/api/Nps/Question?id=${id}`;
        return api({
            method: 'get',
            url,
        });
    }
    async PostQuestion(question: Question) {
        const url = this.endpoint + `/api/Nps/Question`;
        var data = JSON.stringify(question);
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async PutQuestion(question: Question) {
        const url = this.endpoint + `/api/Nps/Question`;
        var data = JSON.stringify(question);
        return api({
            method: 'put',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async DeleteQuestion(id: string) {
        const url = this.endpoint + `/api/Nps/Question?id=${id}`;
        return api({
            method: 'delete',
            url
        });
    }

    //       _                                
    //      / \   _ __  _____      _____ _ __ 
    //     / _ \ | '_ \/ __\ \ /\ / / _ \ '__|
    //    / ___ \| | | \__ \\ V  V /  __/ |   
    //   /_/   \_\_| |_|___/ \_/\_/ \___|_|   
    //
    async GetAnswers() {
        const url = this.endpoint + `/api/Nps/Answers`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetAnswersByQuestion(id: string) {
        const url = this.endpoint + `/api/Nps/AnswersByQuestion?id=${id}&refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetAnswersByUser(email: string) {
        const url = this.endpoint + `/api/Nps/AnswersByUser?email=${email}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetAnswersByClientGroup(clientGroup: string) {
        const url = this.endpoint + `/api/Nps/AnswersByClientGroup?clientGroup=${clientGroup}`;
        return api({
            method: 'get',
            url,
        });
    }
    async GetAnswer(id: string) {
        const url = this.endpoint + `/api/Nps/Answer?id=${id}`;
        return api({
            method: 'get',
            url,
        });
    }
    async PostAnswer(answer: Answer) {
        const url = this.endpoint + `/api/Nps/Answer`;
        var data = JSON.stringify(answer);
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async PutAnswer(answer: Answer) {
        //Esse método, no serviço, só atualiza o prop "Reason".
        const url = this.endpoint + `/api/Nps/Answer`;
        var data = JSON.stringify(answer);
        return api({
            method: 'put',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async DeleteAnswer(id: string) {
        const url = this.endpoint + `/api/Nps/Answer?id=${id}`;
        return api({
            method: 'delete',
            url
        });
    }

    //    _                    _ 
    //   | |    ___   __ _  __| |
    //   | |   / _ \ / _` |/ _` |
    //   | |__| (_) | (_| | (_| |
    //   |_____\___/ \__,_|\__,_|
    //   
    async GetQuestionsForTheUser() {
        const url = this.endpoint + `/api/Nps/QuestionsForTheUser`;
        return api({
            method: 'get',
            url,
        });
    }
}