import React, { useEffect, useState } from 'react';

import './index.css';

interface Props {
    name: string,
    question: string,
    min: number,
    max: number,
    lessText: string,
    moreText: string,
    onChange?: Function
}

const LcVote: React.FC<Props> = (props) => {

    const [answer, setAnswer] = useState<string>('');

    useEffect(() => {
        props.onChange &&
            props.onChange(answer)
    }, [answer])

    return (
        <div className="lc-vote">
            <p className="question">{props.question}</p>
            <div className="options">
                {
                    (() => {
                        var x: any[] = [];
                        for (let index = props.min; index <= props.max; index++) {
                            x.push(
                                <div key={index} className={`option${(answer as unknown as number) == index ? ' active' : ''}`} onClick={() => setAnswer(index as unknown as string)}>{index}</div>
                            );
                        }
                        return x
                    })()
                }
            </div>
            <div className="texts">
                <span>
                    <i className="lci lci-chevron-double-left text-link" />
                    {props.lessText}
                </span>
                <span>
                    {props.moreText}
                    <i className="lci lci-chevron-double-right text-link" />
                </span>
            </div>
            <input type="hidden" name={props.name} value={answer} />
        </div>
    );
}

export default LcVote;