import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import './index.css'

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { MenuState } from '../../../store/reducers/menuReducer';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';

//MSAL
import { authProvider } from '../../../services/authProvider';
import { useDebounce } from 'use-debounce/lib';
import LcDropDown from '../../Generic/LcDropDown';

import LanguageToolsService from '../../../services/languageToolsService';

import { PPModal } from 'processor-plataform-ui'
import ReleaseNotesService from '../../../services/releaseNotesService';
import MarkdownView from 'react-showdown';
import LcLoading from '../../Generic/LcLoading';

interface UserMenuProps {
    items?: { label: string, onClick: Function }[]
}

const UserMenu: React.FC<UserMenuProps> = (props) => {

    const history = useHistory();

    const inputRef = useRef<HTMLInputElement>(null);

    const lt = new LanguageToolsService();

    const [filter, setFilter] = useState<string>();
    const [isShowAsClientActive, setIsShowAsClientActive] = useState<boolean>(sessionStorage.getItem("isShowAsClientActive") ? sessionStorage.getItem("isShowAsClientActive") === 'true' : false);
    const [loadingCustomerChange, setLoadingCustomerChange] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState<any[]>([]);

    const [filterDebounce] = useDebounce(filter, 500);

    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    const [companiesVisible, setCompaniesVisible] = useState(false);
    //Sobre o LC e versões
    const [modalVisibleAbout, setModalVisibleAbout] = useState(false);
    const [modalVisiblePolicies, setModalVisiblePolicies] = useState(false);
    const [loadingPolicies, setLoadingPolicies] = useState(false);
    const [policiesTextTitle, setPoliciesTextTitle] = useState<string>('');
    const [policiesText, setPoliciesText] = useState<string>('');
    const [version, setVersion] = useState<any>(process.env.REACT_APP_LAST_VERSION);

    const handleCompanyChange = async (company: any) => {
        setLoadingCustomerChange(true);
        await authProvider.Renew(company.clientGroupId);
        sessionStorage.setItem("ClientGroupSelected", company.clientGroupId.toString());
        dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
        setLoadingCustomerChange(false);

    }
    // Foco automático para pesquisa de empresa
    useEffect(() => {
        sessionStorage.setItem("isShowAsClientActive", "" + isShowAsClientActive);
    }, [isShowAsClientActive]);
    useEffect(() => {

        const allItems: any[] = [];
        let sortItems: any[] = [];

        user.ClientGroupList &&
            user.ClientGroupList.filter((c: any) => c.defaultClientGroup).map((company: any) => {
                allItems.push(company);
            });

        user.ClientGroupList &&
            user.ClientGroupList
                .filter((c: any) => !c.defaultClientGroup && (!filter ||
                    lt.RemoveAccents(c.descricao.toLowerCase()).indexOf(lt.RemoveAccents(filter)) >= 0))
                .map((company: any) => {
                    sortItems.push(company);
                });

        // Organiza todos os itens em ordem alfabética
        sortItems.sort((a, b) => a.descricao && a.descricao.localeCompare(b.descricao, 'pt-BR'));
        allItems.push(...sortItems)

        setCompanyList(allItems);

    }, [filterDebounce]);

    // Foco automático para pesquisa de empresa
    useEffect(() => {
        if (companiesVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [companiesVisible]);


    const handleClickItem = () => {
        dispatch({ type: 'MENU_USER_VISIBLE', payload: false });
    }

    // Testa se o filtro de empresas está sendo usado para fixar o menu na tela
    const [filterInUse, setFilterInUse] = useState(false);

    const handleFilterOnFocus = () => {
        setFilterInUse(true);
    }

    const handleFilterOnBlur = (inputValue: string) => {
        setFilterInUse(inputValue != "");
        setTimeout(() => { if (inputValue == "") dispatch({ type: 'MENU_USER_VISIBLE', payload: false }); setCompaniesVisible(false) }, 500 * 3);
    }

    //Delay close menu
    var timerMenu;
    const onLeaveMenu = () => {
        clearTimeout(timerMenu);
        timerMenu = setTimeout(() => { if (!filterInUse) dispatch({ type: 'MENU_USER_VISIBLE', payload: false }); }, 500);
    }
    //Delay close companyes
    var timerCompanys;
    const onLeaveCompanyes = () => {
        clearTimeout(timerCompanys);
        timerCompanys = setTimeout(() => { setCompaniesVisible(filterInUse) }, 500);
    }

    const getWikiPolicies = async () => {
        setModalVisiblePolicies(true);
        setLoadingPolicies(true);
        try {
            const service: ReleaseNotesService = new ReleaseNotesService({});
            const response = await service.GetWikiContentPage(version,1, '');
            const pathParts = response.path.split('/');
            setPoliciesTextTitle(pathParts[pathParts.length - 1]);
            setPoliciesText(response.content)
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingPolicies(false)
        }
    }

    return (
        <>
            <div className={`usermenu${menu.showUserMenu ? '' : ' hide'}`} onMouseOver={() => { clearTimeout(timerMenu) }} onMouseOut={() => onLeaveMenu()} >

                <div className="primary">
                    <div className="name">{user.name}</div>

                    {
                        user.ClientGroupList.length > 1
                            ?
                            <>
                                {
                                    !loadingCustomerChange
                                        ?
                                        <div className="item" onClick={() => setCompaniesVisible(!companiesVisible)}>
                                            <span>Empresa: <b>{user.ClientGroupSelectedDescription}</b></span>
                                            <i className="ppi ppi-chevron-right" />
                                        </div>

                                        :
                                        <div className="item">Carregando empresas...</div>
                                }
                            </>
                            :
                            <div className="company"><span>Empresa: <b>{user.ClientGroupSelectedDescription}</b></span></div>
                    }

                    <hr />
                    {user.IsAdm && (user.email?.includes("@processor.com.br") || user.email?.includes("@gotobiz.com.br")) && < div className="item Repositorio" onClick={() => { setIsShowAsClientActive(!isShowAsClientActive); }} onMouseOut={() => onLeaveMenu()}>
                        <div className="label">{isShowAsClientActive ? 'Mostrando como cliente' : 'Mostrando como administrador'}</div>
                    </div>}
                    <div className="item Repositorio" onClick={() => { history.push("/FileRepository") }} onMouseOver={() => { clearTimeout(timerMenu) }} onMouseOut={() => onLeaveMenu()}>
                        <div className="label">Repositório de arquivos</div>
                    </div>
                    <div className="item newRequest" onClick={() => { history.push("/Chamados"); dispatch({ type: 'MENU_NEW_REQUEST', payload: true }) }} onMouseOver={() => { clearTimeout(timerMenu) }} onMouseOut={() => onLeaveMenu()}>
                        <div className="label">{/*<i className="ppi ppi-headset" />*/}Novo Chamado</div>
                    </div>
                    <div className="item requests" onClick={() => { history.push("/Chamados") }} onMouseOver={() => { clearTimeout(timerMenu) }} onMouseOut={() => onLeaveMenu()}>
                        <div className="label">{/*<i className="ppi ppi-clipboard-list" />*/}Visualizar Chamados</div>
                    </div>

                    <hr />

                    <div className="item version" onClick={() => {
                        setModalVisibleAbout(true);
                        handleClickItem();
                    }}>Sobre o LiveCloud {process.env.REACT_APP_LAST_VERSION}</div>
                    <hr />
                    <div className="item" onClick={() => {
                        getWikiPolicies()
                    }}>Políticas de Segurança</div>
                    {
                        props.items && props.items.map((item, i) => {
                            return <div className={`item ${item.label}`} key={i}
                                onClick={() => {
                                    item.onClick();
                                    handleClickItem();
                                }}
                            >{item.label}</div>
                        })
                    }
                    <div className="item" onClick={() => {
                        authProvider.LogOutMSSO();
                        handleClickItem();
                    }}>Sair</div>
                </div>

                <div hidden={!companiesVisible} className="secondary" onMouseOut={() => onLeaveCompanyes()} onMouseOver={() => clearTimeout(timerCompanys)}>
                    <div className="searchform">
                        <i className="ppi ppi-search" />
                        <input
                            style={{ width: '100%' }}
                            name="companyFilter" autoComplete="off" type="text" placeholder="Pesquisar"
                            onChange={(event: any) => { setFilter(event.target.value) }}
                            onFocus={handleFilterOnFocus}
                            onBlur={(event: any) => handleFilterOnBlur(event.target.value)}
                            ref={inputRef}
                        />
                    </div>
                    <hr />
                    <div className="items" >
                        {
                            companyList ?
                                companyList.map((company: any, index: number) => {
                                    if (company.defaultClientGroup) {
                                        return (
                                            <div key={index}>
                                                <div className="item" onClick={() => { handleCompanyChange(company); }}>{company.descricao}</div>
                                                <hr />
                                            </div>
                                        )
                                    } else {
                                        return <div key={index} className="item" onClick={() => { handleCompanyChange(company) }}>{company.descricao}</div>
                                    }
                                })
                                :
                                <span className="item">Nenhum registro encontrado</span>
                        }
                    </div>
                </div>

            </div>

            <PPModal
                visible={modalVisibleAbout}
                title="Sobre o LiveCloud"
                onClose={() => setModalVisibleAbout(false)}
                functions={[
                    { label: "Conheça nossas ofertas", onClick: () => { window.open("https://www.processor.com.br/livecloud", "_blank") } }
                ]}
            >
                <div className="menutopabout">
                    <div className="version mb-3">
                        <h3>
                            Versão
                            <span onClick={() => history.push('/ReleaseNotes')} className="link-text ml-2 " style={{textDecoration: 'none'}}>{version}</span>
                            {/* <span className="text-link"> {version}</span> */}
                            {/* <LcDropDown element={<span className="select-period"><i className="lci lci-chevron-down-alt" /></span>}> */}
                            {/* <LcDropDown element={<span className="select-period"><i className="link-text ml-2" /> {version}</span>}>
                                {
                                    versionSelect.length > 0 && versionSelect.map((item: any, index: any) => {
                                        return <span key={index} className="item" onClick={() => { setVersion(item.value); sessionStorage.setItem('release_notes_version', item.value.toString()); history.push('/ReleaseNotes'); }}>{item.value}</span>;
                                    })
                                }
                            </LcDropDown> */}
                        </h3>
                    </div>

                    <p className="mb-5">

                        O portal LiveCloud fornece informações sobre as soluções LiveCloud
                        adquiridas, além de interface gerencial para acompanhamento dos
                        serviços contratados pelo CLIENTE.

                    </p>

                    <h4>Serviços disponíveis no portal</h4>

                    <ul className="mb-5" >
                        <li> Painéis de visualização dos serviços adquiridos</li>
                        <li>Visões gerenciais de monitoramento dos serviços em uso</li>
                        <li>Disponibilidade dos serviços</li>
                        <li>Evolução e demais alterações no ambiente realizadas ao longo do tempo </li>
                        <li>Solicitação de serviços</li>
                        <li>Abertura de incidentes</li>
                        <li>Novas contratações</li>
                    </ul>

                    <p className="mb-5">
                        As instruções para acesso ao Portal LiveCloud serão encaminhadas
                        para o CLIENTE após a ativação dos serviços, com as informações
                        necessárias para gestão e acompanhamento dos serviços contratados.
                        Acompanhamento dos níveis de serviços (SLA), indicadores (KPI) dos
                        serviços prestados, oportunidades de melhoria, técnicas ou de
                        processos, também poderão ser acompanhados pelo CLIENTE no Portal
                        LiveCloud.
                    </p>

                </div>
            </PPModal>
            <PPModal 
                visible={modalVisiblePolicies} 
                onClose={() => setModalVisiblePolicies(false)} 
                title={policiesTextTitle}
                size="large"
            >
                <LcLoading loading={loadingPolicies} label="Carregando políticas de segurança...">
                    <MarkdownView
                        markdown={policiesText}
                        options={{ tables: true, emoji: true }}
                        style={{
                            lineHeight: '1.6',
                            fontSize: '16px'
                        }}
                    />
                </LcLoading>
                
            </PPModal>
        </>
    );

}

export default UserMenu;