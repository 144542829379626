import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { IconButton, Typography, Box } from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { Assistant, AssistantsResponse } from '../../../services/AIx/assistantsTypes';
import { AssistantService } from '../../../services/AIx/assistants/assistantsService';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import AssistantEditDialog from '../AssistantEditDialog';
import './index.css';

const AssistantsList: React.FC = () => {
    const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
    const [showEditForm, setShowEditForm] = useState(false);
    const queryClient = useQueryClient();
    const observerRef = useRef<IntersectionObserver | null>(null);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const assistantService = new AssistantService({});

    // Use useInfiniteQuery for infinite scrolling
    const {
        data,
        error,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<AssistantsResponse, Error>(
        'assistants',
        ({ pageParam = null }) => assistantService.getAssistants(20, 'desc', pageParam),
        {
            getNextPageParam: (lastPage) => lastPage.has_more ? lastPage.last_id : undefined,
            refetchOnWindowFocus: false,
        }
    );

    const handleShowEditForm = (assistant: Assistant) => {
        setSelectedAssistant(assistant);
        setShowEditForm(true);
    };

    const handleAddAssistant = () => {
        setSelectedAssistant({ id: '', name: '', instructions: '', model: 'gpt-4o' } as Assistant);
        setShowEditForm(true);
    };

    const handleCloseEditForm = () => {
        setShowEditForm(false);
        setSelectedAssistant(null);
        queryClient.invalidateQueries('assistants');
    };

    // IntersectionObserver to load more data
    useEffect(() => {
        if (isLoading || isFetchingNextPage || !hasNextPage) return;

        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                fetchNextPage();
            }
        });

        if (loadMoreRef.current) {
            observerRef.current.observe(loadMoreRef.current);
        }

        return () => observerRef.current?.disconnect();
    }, [isLoading, isFetchingNextPage, hasNextPage, fetchNextPage]);

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>;
    }

    const functionsGeneric = [
        {
            title: 'Novo',
            tooltip: 'Criar grupo',
            icon: 'ppi ppi-plus',
            onClick: handleAddAssistant,
            disabled: false
        }
    ];

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.name || 'N/A'}</span>,
        },
        {
            field: 'model',
            headerName: 'Model',
            width: '30%',
            align: 'left',
            renderCell: (row: Assistant) => <span>{row.model}</span>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: '40%',
            align: 'center',
            renderCell: (row: Assistant) => (
                <div className="action-buttons">
                    <IconButton
                        onClick={() => handleShowEditForm(row)}
                        title="Edit"
                        className="icon-button"
                    >
                        <FaEdit />
                    </IconButton>
                </div>
            ),
        },
    ];

    // Combine all pages of data
    const assistants = data?.pages.flatMap(page => page.data) || [];

    return (
        <Layout
            pageTitle="Assistentes"
            loading={isLoading}
            className="dashboard-layout"
            functionsGeneric={functionsGeneric}
        >
            {isLoading && <LcLoading loading={isLoading} />}

            {!showEditForm && assistants.length > 0 && (
                <Box className="table-container">
                    <LcInfiniteTable
                        columns={columns}
                        rows={assistants}
                    />
                    <div ref={loadMoreRef} className="load-more" style={{ height: '20px' }} />
                    {isFetchingNextPage && <LcLoading loading={isFetchingNextPage} />}
                </Box>
            )}

            {!showEditForm && assistants.length === 0 && (
                <Typography>No assistants found.</Typography>
            )}

            {showEditForm && selectedAssistant && (
                <AssistantEditDialog
                    assistant={selectedAssistant}
                    handleCloseEditForm={handleCloseEditForm}
                />
            )}
        </Layout>
    );
};

export default AssistantsList;
