import React, { useEffect, useState } from 'react';
import LcTable from '../../../../components/Data/LcTable';
import LcIconLink from '../../../../components/Generic/LcIconLink';
import LcLoading from '../../../../components/Generic/LcLoading';
import { NpsService, Answer, Question, AnswerView } from '../../../../services/npsService';

import Moment from 'moment';


//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';

interface Props {
    question: Question,
    onClose: Function
}

const Interactions: React.FC<Props> = (props) => {

    const nps = new NpsService(props);



    const user = useSelector<RootState, UserState>(state => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [interactions, setInteractions] = useState<AnswerView[]>([]);

    const loadAnswers = () => {
        setLoading(true);
        nps.GetAnswersByQuestion(props.question.id)
            .then(response => {
                if (response.data) {
                    let answers = response.data.map(question => {
                        if (question.note == null) {
                            question.note = -1
                        }
                        return question
                    })
                    setInteractions(answers.sort((a: any, b: any) => a.when > b.when ? -1 : 1));
                }

            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            })
    }

    useEffect(loadAnswers, []);

    const columns = [
        { field: 'user', headerName: 'Usuário', width: '25%' },
        { field: 'clientGroup', headerName: 'Grupo', width: '20%' },
        {
            field: 'when', headerName: 'Data e Hora', width: '20%',
            renderCell: (row: any) => {
                var offSet = new Date(row.when).getTimezoneOffset();
                return Moment(new Date(row.when)).subtract(offSet, 'minutes').format("DD/MM/YYYY - HH:mm:ss")
            }
        },
        {
            field: 'note', headerName: 'Nota', width: '10%',
            renderCell: (row: any) => {
                return row.note > 0 ? row.note : 'Adiada'
            }
        },
        { field: 'reason', headerName: 'Motivo', width: '25%' },
    ]

    const marks = [{ label: 'Adiadas', value: -1 }, { label: props.question.minimum, value: props.question.minimum }, { label: props.question.maximum, value: props.question.maximum }]

    return (
        <div>
            <div className="sequence">
                <LcIconLink icon="ppi ppi-x" onClick={() => props.onClose()} />
            </div>
            <LcLoading loading={loading} label="Carregando interações...">
                <h3>Nome: {props.question.name}
                    &nbsp;
                    <span className="text-danger">
                        <i className="lci lci-chevron-down-alt" /> {props.question.minimum}
                    </span>
                    &nbsp;
                    <span className="text-success">
                        <i className="lci lci-chevron-up-alt" />  {props.question.maximum}
                    </span>
                </h3>
                <h4>Pergunta: {props.question.text}

                </h4>

                <LcTable
                    data={interactions}
                    columns={columns}
                    filterFields={[
                        { label: 'Data entre', name: 'when', type: 'spanDateTime' },
                        { label: 'Grupo', name: 'clientGroup', type: 'text' },
                        { label: 'Usuário', name: 'user', type: 'text' },
                        { label: 'Notas', name: 'note', type: 'interval', max: props.question.maximum, min: -1, marks: marks },
                    ]}
                    height="100%"
                />

            </LcLoading>
        </div>
    );
}

export default Interactions;