import React, { useState } from 'react';
import './index.css';

type Position = "left" | "center" | "right";
type Trigger = "click" | "hover";

interface Props {
    element?: React.ReactNode,
    position?: Position,
    trigger?: Trigger,
    hold?: boolean,
    disabled?: boolean,
    maxHeight?: string
}

const LcDropDown: React.FC<Props> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [top, setTop] = useState<boolean>(false);
    const [height, setHeight] = useState(0);

    const handleClose = (e: any) => {

        setTop(window.innerHeight - e.clientY < 172 + 28);

        if (!visible)
            (!props.trigger || props.trigger == 'click') && setVisible(true)
        else
            !props.hold && (!props.trigger || props.trigger == 'click') && setVisible(false);
    }


    //Delay close 
    var timerMenu;
    const onMouseLeave = () => {
        clearTimeout(timerMenu);
        timerMenu = setTimeout(() => setVisible(false), 500);
    }

    const getContentTransform = () => {
        switch (props.position) {
            case "center":
                return "translateX(-50%)";
            case "right":
                return !top ? "translateX(-100%)" : `translate(-100%, calc(-100% + -${height}px))`;
        }
    }

    return (
        <div className="lc-dropdown"
            onClick={handleClose}
            onMouseOver={async (e) => {
                props.trigger == 'hover' &&
                    setVisible(true);
                clearTimeout(timerMenu);
            }}
            onMouseLeave={onMouseLeave}
        >
            <div className="element"
                ref={(event: any) => {
                    event && event.offsetHeight &&
                        setHeight(event.offsetHeight)
                }}
            >
                {props.element}
            </div>
            {
                visible &&
                <div className={`body ${props.position}`}>
                    <div className="content scrollable-v"
                        style={{ maxHeight: props.maxHeight, transform: getContentTransform() }}>
                        {props.children}
                    </div>
                </div>
            }
        </div >
    );
}

export default LcDropDown;