import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { CostCenterService, FilterDashboard, FinOpsPivotDataResult } from '../../../services/costCenter/costCenterService';
import LcLoading from '../../../components/Generic/LcLoading';
import {  useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import './index.css';
import PivotTableWebix from '../../../components/webix';
import moment from 'moment';
import { useQuery } from 'react-query';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiCalendarBlankLight, PiCalendarPlusLight,  PiCloudArrowDownLight } from 'react-icons/pi';


const FinOpsAnalyticDashBoard: React.FC = (props) => {
    const costCenterService = new CostCenterService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [showMargin, setShowMargin] = useState<boolean>(false);
    const [accumulate, setAccumulate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [datePack, setDatePack] = useState<{ month: string, year: string, clientGroupId: number | undefined, refresh: string, accumulate: boolean }>();
    const [fields, setFields] = useState<any[]>();
    const [selectedView, setSelectedView] = useState<boolean>(false);
    const { data: pivotData, isLoading: loadingpivotData, refetch: refetchReportKPI, isRefetching: isRefetchingKPIs }
        = useQuery<FinOpsPivotDataResult[]>(['pivotData', datePack], async () => {
            return await loadData(datePack);
        }, {
            keepPreviousData: true,
            enabled: true,
            staleTime: 7200000,
            onSuccess: () => {
                console.log('onSuccess');
                setLoading(false);
            },
            optimisticResults: true
        });

    useEffect(() => {
        setAccumulate(false);
        var dateR = new Date();
        dateR.setDate(dateR.getDate() - 1);
        var currentMonth = (dateR.getMonth() + 1).toString();
        var currentYear = dateR.getFullYear().toString();
        setDatePack({ month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected, refresh: Math.random().toString(), accumulate: accumulate });
        refetchReportKPI();
        setLoading(true);
        setSelectedView(localStorage.getItem('visionSelected') ? true : false)
    }, [   refetchReportKPI, user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => { setLoading(false) }, [pivotData]);

    const initData = () => {
        setLoading(true);
        var dateR = new Date();
        dateR.setDate(dateR.getDate() - 1);
        var currentMonth = (dateR.getMonth() + 1).toString();
        var currentYear = dateR.getFullYear().toString();
        setDatePack({ month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected, refresh: '', accumulate: accumulate });
    }

    async function loadData(refresh): Promise<FinOpsPivotDataResult[]> {
        var dateR = new Date();
        dateR.setDate(dateR.getDate() - 1);
        var currentMonth = (dateR.getMonth() + 1).toString();
        var currentYear = dateR.getFullYear().toString();
        if (datePack) {
            currentYear = datePack.year;
            currentMonth = datePack.month;
        }

        try {
            var filter = {
                clientGroupId: Number(user.ClientGroupSelected ? user.ClientGroupSelected : '0'),
                costCenterID: 0,
                contractID: 0,
                componentName: '',
                productName: '',
                serviceCode: '',
                serviceName: '',
                provedor: '',
                ano: Number(currentYear),
                mes: Number(currentMonth),
                dia: 0,
                gtmId: 0,
                user: user.UserId,
                reportType: '1',
                displayType: '1',
                showChildrenCostCenters: 1,
                periodo: 2
            } as FilterDashboard;

            var responseAData = (await costCenterService.GetDataDetails(filter)) as FinOpsPivotDataResult[];

            var fieldBase = [{ id: 'Srv', value: "Serviço", type: "text" }];
            fieldBase.push({ id: 'Prv', value: "Provedor", type: "text" });
            fieldBase.push({ id: 'Ano', value: "Ano", type: "number" });
            fieldBase.push({ id: 'Mes', value: "Mês", type: "number" });
            fieldBase.push({ id: 'Dia', value: "Dia", type: "number" });
            fieldBase.push({ id: 'Cdc', value: "Centro de Custo", type: "text" });
            fieldBase.push({ id: 'Ctr', value: "Contrato", type: "text" });
            fieldBase.push({ id: 'Rsg', value: "Grupo", type: "text" });
            fieldBase.push({ id: 'Rec', value: "Recurso", type: "text" });
            fieldBase.push({ id: 'parentCostCenter', value: "Centro de Custo Pai", type: "text" });

            if (responseAData[0].Smg === true) {
                setShowMargin(true);
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Ccrd', value: "Regra CdeC Nome", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                fieldBase.push({ id: 'Cst', value: "Custo", type: "number" });
                fieldBase.push({ id: 'Mrg', value: "Margem", type: "number" });
                fieldBase.push({ id: 'Tax', value: "Taxa", type: "number" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                fieldBase.push({ id: 'Acc', value: "Assinatura", type: "text" });
                fieldBase.push({ id: 'Acn', value: "Nome Assinatura", type: "text" });
                fieldBase.push({ id: 'Rgn', value: "Região", type: "text" });
                fieldBase.push({ id: 'Rci', value: "Id do Recurso", type: "text" });
            } else if (responseAData[0].Spt === true) {
                fieldBase.push({ id: 'Pct', value: "Porcento", type: "number" });
            } else {
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Acc', value: "Assinatura", type: "text" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Ccrd', value: "Regra CdeC Nome", type: "text" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                fieldBase.push({ id: 'Acn', value: "Nome Assinatura", type: "text" });
                fieldBase.push({ id: 'Rgn', value: "Região", type: "text" });
                fieldBase.push({ id: 'Rci', value: "Id do Recurso", type: "text" });
            }

            setFields([...fieldBase]);
            if (pivotData && pivotData.length > 0 && accumulate) {
                var dataPivotDates = groupByMesAnoAndExtract(pivotData);
                var dataresult = [...responseAData.filter(c => dataPivotDates.filter(x => Number(x.Ano) === Number(c.Ano) && x.Mes === c.Mes).length === 0), ...(pivotData as FinOpsPivotDataResult[])];
                return costCenterService.OrderDataDetailsAnalitico(dataresult);
            }

            return responseAData;
        } catch (error) {
            if (error && error.toString().includes('ERR_CERT_AUTHORITY_INVALID')) {
                alert(error);
            }
            console.error(error);
        }
        return [];
    }
    function groupByMesAnoAndExtract(data: FinOpsPivotDataResult[]): { Mes: number; Ano: number }[] {
        var resultMonthYear = [] as { Mes: number, Ano: number }[];
        const grouped = data.reduce((acc, item) => {
            const key = `${item.Ano}-${item.Mes}`; // Grouping key, formatted as "Year-Month"
            if (!acc[key]) {
                acc[key] = [];
            }
            // Check if the current combination of Mes and Ano already exists to avoid duplicates
            const exists = acc[key].some(x => x.Mes === item.Mes && x.Ano === item.Ano);
            if (!exists) {
                acc[key].push({ Mes: item.Mes, Ano: item.Ano });
                resultMonthYear.push({ Mes: item.Mes, Ano: item.Ano });
            }
            return acc;
        }, {} as Record<string, { Mes: number; Ano: number }[]>);

        return resultMonthYear;
    }

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setDatePack({ month: date.split('-')[1], year: date.split('-')[0], clientGroupId: user.ClientGroupSelected, refresh: '', accumulate: accumulate });
        setLoading(true)
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let header = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (header.split(';').indexOf(index) === -1) {
                    header += index + ';';
                }
                if (line !== '') line += ';';
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return header.slice(0, -1) + '\r\n' + str;
    };

    //const convertToCSV = (objArray) => {
    //    const array = objArray.map(obj => transformObjectKeys(obj));
    //    if (array.length > 0) {
    //        const headers = Object.keys(array[0]).join(';') + '\r\n';
    //        let csvString = array.reduce((acc, obj) => {
    //            const row = Object.values(obj).map(field => `"${field}"`).join(';');
    //            return acc + row + '\r\n';
    //        }, headers);
    //        return csvString;
    //    }
    //    return '';
    //};

    useEffect(() => {
        // console.log('isRefetchingKPIs' + isRefetchingKPIs + 'isLoadingKPIs' + loadingpivotData)
        setLoading(loadingpivotData || isRefetchingKPIs);
    }, [loadingpivotData, isRefetchingKPIs])

    //const handleDownload = () => {

    //    const jsonString = JSON.stringify(dataTransformed);
    //    const blob = new Blob([jsonString], { type: 'application/json' });
    //    const href = URL.createObjectURL(blob);
    //    const link = document.createElement('a');
    //    link.href = href;
    //    link.download = "data.json"; // Specify the download filename
    //    document.body.appendChild(link);
    //    link.click();
    //    document.body.removeChild(link);
    //    URL.revokeObjectURL(href);
    //};
    //const handleDownload = () => {

    //    const csvString = convertToCSV(jsonData);
    //    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    //    const href = URL.createObjectURL(blob);
    //    const link = document.createElement('a');
    //    link.href = href;
    //    link.download = "data.csv"; // Specify the download filename
    //    document.body.appendChild(link);
    //    link.click();
    //    document.body.removeChild(link);
    //    URL.revokeObjectURL(href);
    //};
    const handleDownload = () => {
        var jsonData: any[] = [];
        pivotData?.forEach((row) => {
            var line = transformObjectKeys(row);
            if (line) {
                jsonData.push(line);
            }
        });
        const csvString = convertToCSV(jsonData);
        const BOM = "\uFEFF"; // UTF-8 Byte Order Mark
        const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = "data.csv"; // Specify the download filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };
    //    return <div style={{ fontSize:'22px'}}>{user.IsAdm && <RiDownloadCloudLine onClick={handleDownload} />}</div>;
    //}
    function transformObjectKeys(object) {
        if (!fields) {
            return [];
        }
        const transformedObject = {};
        Object.keys(object).forEach(key => {
            const field = fields.find(field => field.id === key);
            if ((key === 'Dat' || key === '_1' || key === '_2' || key === '_3' || key === '_4')) {
                return;
            }
            if (field) {
                transformedObject[field.value] = object[key];
            }
        });
        return transformedObject;
    }

    return (<Layout
        functionsGeneric={user.IsAdm ? [
        { icon: !accumulate ? <PiCalendarBlankLight /> : <PiCalendarPlusLight />, onClick: () => { setAccumulate(!accumulate); }, tooltip: "Acumular períodos" },
        { icon: <PiCloudArrowDownLight />, onClick: () => { handleDownload(); }, tooltip: "Download CSV" },
    ]
        : 
        [{ icon: !accumulate ? <PiCalendarBlankLight /> : <PiCalendarPlusLight />, onClick: () => { setAccumulate(!accumulate); }, tooltip: "Acumular períodos" }]}
        pivot pageTitle={'Dash'} colorIcon={selectedView ? '#FFBE15' : ''} periodSelection={periodSelection} >
        <LcLoading loading={loadingpivotData || isRefetchingKPIs || loading} loadingType='Helix' >
            {fields && fields.length > 0 && pivotData && pivotData.length > 0
                ? <PivotTableWebix
                    setSelectedView={setSelectedView}
                    key={"1"}
                    data={pivotData}
                    fields={fields}
                    classTable='analytical'
                    mode="tree"
                    chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                    structure={{
                        rows: ["Prv", "Acn", "Srv"],
                        columns: accumulate ? ["Ano", "Mes"] : ["Mes"],
                        values:
                            showMargin ?
                                [{ name: "Cst", operation: ["sum"] },
                                { name: "Tax", operation: ["sum"] },
                                { name: "Mrg", operation: ["sum"] },
                                { name: "Vbr", operation: ["sum"] }] : [{ name: "Vbr", operation: ["sum"] }],
                        filters: [
                            { name: "Prv" },
                            { name: "Acc" },
                            { name: "Cdc" },
                            { name: "Ctr" },
                            { name: "Srv" },
                            { name: "Cat" },
                            { name: "Mes" },
                            { name: "Dia" },
                            { name: "Rec" }
                        ]
                    }}
                /> : <LcNoData />}
        </LcLoading>
    </Layout>);
};
export default FinOpsAnalyticDashBoard;