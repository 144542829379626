import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { SaasService, UserCostCenterLicenseCountry } from '../../../services/saas/saasService';
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcLoading from '../../../components/Generic/LcLoading';
import PivotTableWebix from '../../../components/webix';

const ADUsers: React.FC = (props) => {
	const user = useSelector<RootState, UserState>(state => state.user);
	const [data, setData] = useState<UserCostCenterLicenseCountry[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const saasService = new SaasService(props);

	useEffect(() => {
		if (user.ClientGroupSelected) {
			setLoading(true);
			saasService.RetrieveADUsers()
				.then(response => {
					if (response != null) {
						let _sorted = response
							.sort((a, b) => a.country > b.country ? 1 : -1)
							.sort((a, b) => a.country === b.country ? (a.userName > b.userName ? 1 : -1) : 0)
						setData(_sorted);
					}
				})
				.catch(error => {
					console.error('Erro buscando usuários:', error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [user.ClientGroupSelected, user.refreshFlag]);

	const pivotFields = [
		{ id: 'country', value: 'País', type: 'text', width: 50 },
		{ id: 'userName', value: 'E-mail', type: 'text' },
		{ id: 'officeLocation', value: 'Escritório', type: 'text' },
		{ id: 'jobTitle', value: 'Cargo', type: 'text' },
		{ id: 'license', value: 'Licenças', type: 'text' },
		{ id: 'costCenter', value: 'CC', type: 'text' },
	];

	const pivotStructure = {
		rows: ['country', 'userName', 'officeLocation', 'jobTitle', 'license'],
		columns: [],
		values: [{ name: 'costCenter', operation: 'count' }],
		filters: [{ name: 'userName' }, { name: 'country' }]
	};

	return (
		// <Layout pageTitle="Usuários do AD" pivot>
		<LcLoading loading={loading} label='Carregando usuários do Active Directory, aguarde...'>
			{data.length > 0 && (
				<PivotTableWebix
					idView={'ADUsersPivot'}
					key={'ADUsersPivot'}
					data={data}
					fields={pivotFields}
					structure={pivotStructure}
					style={{ minWidth: '100%', minHeight: '100%' }}
					rowLimit={100000}
				/>
			)}
		</LcLoading>
		// </Layout>
	);
};

export default ADUsers;