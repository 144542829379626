// FilterPanel.tsx
import React, { useState, useEffect } from 'react';
import './index.css'; // Import the CSS file
import InputFieldFilter from './InputFieldFilter'; // Change the import statement
import PPDropDown from '../../PPDropDown';
import LcCheckBox from '../../Form/LcCheckBox'; 
import { DateRangePick, SliderField } from '../../Form/Input';
import moment from 'moment'; 
import { FilterField } from './FilterField';
import { useDispatch } from 'react-redux';
import { patchManagerReducer } from '../../../store/reducers/patchManagerReducer';
 
interface DataItem {
    [key: string]: any;
}

interface FilterPanelProps {
    reducerFilter?: any
    filter: FilterField[];
    data: DataItem[];
    onFilteredData: (filteredData: DataItem[]) => void;
    translations: { [key: string]: { label: string; type: string; topDropdown?: boolean; values?: { [key: string]: string } }; };
    clearFilters?: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ filter, data, onFilteredData, translations, clearFilters, reducerFilter }) => {
    const [localFilter, setLocalFilter] = useState<FilterField[]>(filter);
    const [tempFilters, setTempFilters] = useState<{ [key: string]: string | string[] | any }>({});
    const [selectFilter, setSelectFilter] = useState<boolean> (false)
    const [resetKey, setResetKey] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!reducerFilter) setLocalFilter(filter);
    }, [filter, reducerFilter]);


    useEffect(() => {
        if(reducerFilter && data.length > 0) {
            const key = Object.keys(reducerFilter)[0]; 
            const value = reducerFilter[key]; 
            handleInputChange(key, value);
        } 
    },[reducerFilter, data])


    const handleInputChange = (name: string, value: any) => {
        const updatedFilters = localFilter.map(f => {
            if (f.name === name) {
                return { ...f, value };
            }
            f.type === 'multiSelect' || f.type === 'select' ? setSelectFilter(true) : setSelectFilter(false);
            return f;
        });
        setLocalFilter(updatedFilters);
        setTempFilters({ ...tempFilters, [name]: value,});
    };

    // const handleTempFilterChange = (field: string, value: any) => {
    //     setTempFilters(prevFilters => ({
    //         ...prevFilters,
    //         [field]: value
    //     }));
    // };

    const handleClearFiltersClick = () => {
        console.log(clearFilters, patchManagerReducer)
        if (clearFilters) {
            dispatch({ type: 'RESET_SYSTEM_PATCH'});
            dispatch({ type: 'RESET_PATCH_PATCH'});
            clearFilters();
            resetLocalFiltersAndRerender();
            
        } else {
            handleFilterClear();
            dispatch({ type: 'RESET_PATCH_PATCH'});
            dispatch({ type: 'RESET_SYSTEM_PATCH'});
        }
    };

    const handleFilterClear = () => {
        resetLocalFiltersAndRerender();
        onFilteredData(data); // Reset to original data
    };

    const resetLocalFiltersAndRerender = () => {
        const resetFilter = filter.map(f => ({ ...f, value: f.type === 'multiSelect' ? [] : '' }));
        setLocalFilter(resetFilter);
        setResetKey(prevKey => prevKey + 1);
        setTempFilters({});
    };

    const applyFilters = () => {
        const filteredData = filterData(data, tempFilters, selectFilter);
        onFilteredData(filteredData);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        applyFilters();
    };

    const isNumber = (value: any) => !isNaN(value);

    const applyNumericFilter = (itemValue: any, filterValue: string) => {
        const match = filterValue.match(/^([<>]=?)?(\d+(\.\d+)?)$/);
        if (!match) return itemValue.toString().includes(filterValue);

        const [, operator, number] = match;
        const numericValue = parseFloat(number);

        switch (operator) {
            case '>':
                return parseFloat(itemValue) > numericValue;
            case '>=':
                return parseFloat(itemValue) >= numericValue;
            case '<':
                return parseFloat(itemValue) < numericValue;
            case '<=':
                return parseFloat(itemValue) <= numericValue;
            default:
                return parseFloat(itemValue) === numericValue;
        }
    };

    const filterData = (data: DataItem[], filters: { [key: string]: any }, notInclude?: boolean) => {
        return data.filter(item => Object.entries(filters).every(([key, value]) => {
            if (!value) return true;
            if (Array.isArray(value) && value.length === 0) return true;
            if (Array.isArray(value)) {
                return value.some(v => {
                    const itemValue = item[key].toString().toLowerCase();
                    const filterValue = v.toString().toLowerCase();
                    if (notInclude) {
                        return itemValue === filterValue;
                    } else {
                        return itemValue.includes(filterValue);
                    }
                });
            }
            if (typeof value === 'string') {
                if (isNumber(item[key])) {
                    return applyNumericFilter(item[key], value);
                }
                return item[key].toString().toLowerCase().includes(value.toLowerCase());
            } else if (typeof value === 'object' && 'startDate' in value && 'endDate' in value) {
                const { startDate, endDate } = value;
                const itemDate = moment(item[key]).startOf('day');
                const start = startDate ? moment(startDate).startOf('day') : null;
                const end = endDate ? moment(endDate).endOf('day') : null;

                if (start && end) return itemDate.isBetween(start, end, null, '[]');
                if (start) return itemDate.isSameOrAfter(start);
                if (end) return itemDate.isSameOrBefore(end);
                return true;
            }
            return false;
        }));
    };

    const getUniqueValues = (field: string) => {
        const values = data
            .map(item => item[field as keyof DataItem])
            .filter(value => value !== null && value !== undefined);
        return [...new Set(values)];
    };

    const renderFilters = () => {
        return localFilter.map((f, i) => {
            const translation = translations[f.name] || { label: f.label, type: f.type, value: f.value };
            const uniqueValues = getUniqueValues(f.name);
            if (!['dropdown', 'multiSelect', 'date', 'text', 'spanDateTime', 'interval', 'checkBox', 'select'].includes(translation.type)) return null;
            return (
                <div key={i} className="filter-field">
                    {translation.type === 'spanDateTime' && (
                        <DateRangePick
                            key={resetKey}
                            label={translation.label}
                            name={f.name}
                            value={f.value}
                            onChange={e => handleInputChange(f.name, e)}
                            width='100%'
                        />
                    )}
                    {/*{translation.type === 'interval' && marks && (*/}
                    {/*    <SliderField*/}
                    {/*        marks={f.marks}*/}
                    {/*        name={f.name}*/}
                    {/*        label={translation.label}*/}
                    {/*        min={f.min || 0}*/}
                    {/*        max={f.max || 100}*/}
                    {/*        value={f.value}*/}
                    {/*        onChange={e => handleInputChange(f.name, e.target.value)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {translation.type === 'checkBox' && (
                        <LcCheckBox
                            title={translation.label}
                            name={f.name}
                            checked={f.value === 'true'}
                            onChange={e => handleInputChange(f.name, e.target.checked ? 'true' : 'false')}
                        />
                    )}
                    {translation.type === 'select' && (
                        <PPDropDown
                            key={resetKey}
                            menuPlacement={translation.topDropdown ? 'top' : 'auto'}
                            name={f.name}
                            onChange={e => handleInputChange(f.name, e.value)}
                            options={uniqueValues.map(value => ({ label: translation.values?.[value] || value, value }))}
                            title={translation.label}
                            placeholder='Selecione...'
                        />
                    )}
                    {translation.type === 'multiSelect' && (
                        <PPDropDown
                            key={resetKey}
                            menuPlacement={translation.topDropdown ? 'top' : 'auto'}
                            name={f.name}
                            defaultValue={(f.value && reducerFilter) ? f.value.map(value => ({ label: f.values?.[value] || value, value })) : undefined}
                            isMulti={true}
                            options={uniqueValues.map(value => ({ label: translation.values?.[value] || value, value }))}
                            onChange={e => handleInputChange(f.name, e.map(option => option.value))}
                            title={translation.label}
                            maxMenuHeight={150}
                            placeholder='Selecione...'
                            />
                        )}
                    {translation.type === 'text' && (
                        <InputFieldFilter
                            label={translation.label}
                            type={translation.type as 'number' | 'time' | 'text' | 'date' | 'datetime-local' | 'month' | 'week' | 'email' | 'password' | 'tel' | 'url' | 'file'}
                            value={f.value}
                            onChange={value => handleInputChange(f.name, value)}
                            placeholder='Digite...'
                        />
                    )}
                    {translation.type === 'date' && (
                        <InputFieldFilter
                            label={translation.label}
                            type={translation.type as 'number' | 'time' | 'text' | 'date' | 'datetime-local' | 'month' | 'week' | 'email' | 'password' | 'tel' | 'url' | 'file'}
                            value={f.value || ''}
                            onChange={value => handleInputChange(f.name, value)}
                        //placeholder='Digite...'
                        />
                    )}
                </div>
            );
        });
    };

    return (
        <form onSubmit={handleSubmit} className="right-sidepanel-filters">
            <div style={{ gridGap: '1rem' }} className="principal-filters scrollable-v h-100p">
                {renderFilters()}
            </div>

            <div className="space-between mt-4">
                <button type="submit" className="lc-button bg-info">Filtrar</button>
                <button type="button" className="lc-button" onClick={handleClearFiltersClick}>Limpar</button>
            </div>
        </form>
    );
};

export default FilterPanel;