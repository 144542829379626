import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Box } from '@mui/material';
import { Assistant, Tool } from '../../../services/AIx/assistantsTypes';
import { AssistantService } from '../../../services/AIx/assistants/assistantsService';
import FileSearchService from '../../../services/AIx/filesearch/fileSearchService';
import { FaCog } from 'react-icons/fa';
import VectorStoreFileList from './VectorStoreFileList';
import { PPInput } from 'processor-plataform-ui';
import PPCheckBox from '../../../components/Form/PPCheckBox';
import PPDropDown from '../../../components/PPDropDown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import ExpandableTextBox from '../../../components/Form/PPTextAreaExplandable';
import FormFunctionCall from './FunctionCallModal';

interface EditAssistantFormProps {
    handleClose: () => void;
    assistant: Assistant;
}

const EditAssistantForm: React.FC<EditAssistantFormProps> = ({ handleClose, assistant }) => {
    const [formData, setFormData] = useState({ ...assistant });
    const [modelOptions, setModelOptions] = useState([{ value: 'gpt-4o-mini', label: 'gpt-4o-mini' }]);
    const user = useSelector<RootState, UserState>(state => state.user);
    const assistantService = new AssistantService({});
    const fileSearchService = new FileSearchService({});
    const [isFunctionCallModalVisible, setIsFunctionCallModalVisible] = useState(false);

    const loadOptionsModels = async () => {
        const modelOptionsFromOpenAI = await assistantService.GetOpenAIModels();
        setModelOptions(modelOptionsFromOpenAI);
    };

    useEffect(() => {
        loadOptionsModels();
    }, [user.refreshFlag, user.ClientGroupSelected]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>) => {
        try {
            console.log(e);

            const { name, value } = e.target;
            if (name === 'tools.file_search' || name === 'tools.code_interpreter') {
                const isChecked = value;
                let updatedTools = formData && formData.tools && [...formData.tools] || [];
                if (name === 'tools.file_search' || name === 'tools.code_interpreter') {
                    const toolType = name.split('.')[1];
                   // debugger;
                    if (isChecked)
                        if (toolType === 'file_search') {
                            if (!formData.tool_resources) {
                                setFormData({
                                    ...formData,
                                    tool_resources: { file_search: { vector_store_ids: ["vs_randon"] }, code_interpreter: { file_ids: [] } }
                                })
                            } else if (formData.tool_resources
                                && formData.tool_resources.file_search
                                && formData.tool_resources.file_search.vector_store_ids.length === 0) {
                                formData.tool_resources = { file_search: { vector_store_ids: ["vs_randon"] }, code_interpreter: { file_ids: [] } }
                            }
                        }
                    if (isChecked) {
                        updatedTools.push({ type: toolType });
                    } else {
                        updatedTools = updatedTools.filter(tool => tool.type !== toolType);
                    }
                }
            } else {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
            //if (name.startsWith('tools.')) {
            //    const isChecked = e.target.checked;
            //    let updatedTools = formData.tools ? [...formData.tools] : [];
            //    const toolType = name.split('.')[1];
            //    if (isChecked) {
            //        updatedTools.push({ type: toolType });
            //    } else {
            //        updatedTools = updatedTools.filter(tool => tool.type !== toolType);
            //    }
            //    setFormData({
            //        ...formData,
            //        tools: updatedTools
            //    });
            //} 
        } catch (e) {

        }

    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await assistantService.updateAssistant(formData.id, formData);
            handleClose();
        } catch (error) {
            console.error('Error updating assistant:', error);
        }
    };

    return (
        <Box sx={{ p: 2, minHeight: "calc(100vh - 180px)" }}>
            {isFunctionCallModalVisible ?
                <FormFunctionCall
                    isVisible={isFunctionCallModalVisible}
                    onClose={() => setIsFunctionCallModalVisible(false)}
                />
                :
                <form onSubmit={handleSubmit} style={{ justifyContent: 'space-between', minWidth: '100%' }}>
                    <Box >
                        {formData.id}
                        <PPInput
                            title="Nome"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <ExpandableTextBox
                            label="Instruções"
                            name="instructions"
                            value={formData.instructions}
                            onChange={handleInputChange}
                            placeholder="Insira seu texto aqui"
                            modalTitle="Editar Detalhes"
                        />
                        <PPDropDown
                            name="model"
                            title="Modelo"
                            options={modelOptions}
                            defaultValue={modelOptions.find(option => option.value === formData.model)}
                            onChange={(option: any) => option && handleInputChange({ target: { name: 'model', value: option.value } } as any)}
                            width="100%"
                        />
                        <div style={{ padding: '5px' }} >
                            <PPCheckBox
                                title="Documentos"
                                checked={formData.tools?.some(tool => tool.type === "file_search") || false}
                                onChange={handleInputChange}
                                name="tools.file_search"
                            />
                            <PPCheckBox
                                title="Web Browsing"
                                checked={formData.tools?.some(tool => tool.type === "web_browsing") || false}
                                onChange={handleInputChange}
                                name="tools.web_browsing"
                            />
                            <PPCheckBox
                                title="DALL·E Image Generation"
                                checked={formData.tools?.some(tool => tool.type === "dalle_image_generation") || false}
                                onChange={handleInputChange}
                                name="tools.dalle_image_generation"
                            />
                            <PPCheckBox
                                title="Code Interpreter & Data Analysis"
                                checked={formData.tools?.some(tool => tool.type === "code_interpreter") || false}
                                onChange={handleInputChange}
                                name="tools.code_interpreter"
                            />
                        </div>
                        {formData.tool_resources?.file_search?.vector_store_ids && formData.tool_resources?.file_search?.vector_store_ids?.length > 0 &&
                            <VectorStoreFileList assistant={assistant} vector_store_ids={formData.tool_resources.file_search.vector_store_ids} />
                        }
                        <div style={{ marginTop: '0px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} >
                            <FaCog size={20} color="#000" onClick={() => setIsFunctionCallModalVisible(true)} />
                            <span style={{ marginLeft: '8px' }}>Configurar Function Call</span>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: -2, minWidth: '100%', }} >
                        <button className="pp-button" onClick={handleClose}>
                            Voltar
                        </button>
                        <button className="pp-button" type="submit">
                            Salvar
                        </button>
                    </Box>
                </form>
            }
        </Box>
    );
};

export default EditAssistantForm;
