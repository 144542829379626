import React, { useEffect, useState } from 'react';

import { Question, Answer, NpsService } from '../../../services/npsService';
import { HeyHoCallService } from '../../../services/heyHoCallService';

import Moment from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

import Alert from '../../../components/Dialog/Alert';
import Confirmation from '../../../components/Dialog/Confirmation';
import { TextAreaField } from '../../../components/Form/Input';
import LcVote from '../../../components/Form/LcVote';
import SideModal from '../../../components/Layout/SideModal/SideModal';


const NPS: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const nps = new NpsService(props);
    const heyho = new HeyHoCallService(props);

    const [questions, setQuestions] = useState<Question[]>([]);
    const [questionsWhy, setQuestionsWhy] = useState<Question[]>([]);
    const [answersWhy, setAnswersWhy] = useState<Answer[]>([]);
    const [npsThanksVisible, setNpsThanksVisible] = useState<boolean>(false);

    const loadQuestions = () => {
        nps.GetQuestionsForTheUser()
            .then(response => {
                if (response.data) {
                    setQuestions(response.data);
                }
            })
            .catch(console.warn)
    }

    useEffect(loadQuestions, [])

    const handleSubmitNPS = (event: any) => {
        event.preventDefault();

        var answer: Answer = {
            clientGroup: user.ClientGroupList.find(c => c.defaultClientGroup).clientGroupId || 0,
            user: user.email?.toLowerCase() || '',
            question: '',
            note: undefined,
            reason: ''
        }

        questions.forEach(q => {

            var vote = event.target[q.id + '_vote'].value;

            var _answer = { ...answer, question: q.id, note: vote }

            nps.PostAnswer(_answer)
                .then(response => {
                    if (q.triggerAlertBelow && vote <= q.triggerAlertBelow) {

                        if (response.data) {

                            setQuestionsWhy([...questionsWhy, q]);
                            setAnswersWhy([...answersWhy, response.data]);
                        }
                    } else {
                        setNpsThanksVisible(true);
                    }
                })
                .catch(console.warn);
        })

        setQuestions([]);

        event.target.reset();
    }

    const handleSubmitAnswersWhy = (event: any) => {
        event.preventDefault();

        questionsWhy.forEach(q => {

            var reason = event.target[q.id + '_reason'].value;

            var answer = answersWhy.find(a => a.question == q.id);

            if (answer) {
                answer.reason = reason.trim().length > 0 ? reason : '...sem resposta';
                nps.PutAnswer(answer).catch(console.warn);

                //#region Abrir chamado
                var agendamento = {
                    id: 0,
                    titulo: 'NPS com nota baixa',
                    descricao: `Ao responder a pergunta "${q.name}" o usuário "${user.name} (${user.email})" deu a nota ${answer.note}`,
                    contrato: 0,
                    dataInicio: Moment().format('YYYY-MM-DD HH:mm:ss'),
                    duracao: null,
                    idCliente: null,
                    idContratoProduto: null,
                    idUsuario: '863b6970-d717-419f-b97a-467f19bae0ae',
                    tipoTarefa: null,
                    situacao: null,
                    dataCriacao: Moment().format('YYYY-MM-DD HH:mm:ss'),
                    idSource: null,
                    dataAtualizacao: Moment().format('YYYY-MM-DD HH:mm:ss'),
                    status: null,
                    clientGroupId: 492, //Fixo Processor
                    clientGroup: null,
                    aspNetUsers: null,
                    validationMenssage: null
                }

                heyho.CriarAnonimo(agendamento, null);
                //#endregion

            }
        })

        setQuestionsWhy([]);
        setAnswersWhy([]);

        setNpsThanksVisible(true);

        event.target.reset();
    }

    const handlePostponeNPS = () => {

        var _answer: Answer = {
            clientGroup: user.ClientGroupList.find(c => c.defaultClientGroup).clientGroupId || 0,
            user: user.email?.toLowerCase() || '',
            question: '',
            note: undefined
        }

        questions.forEach(q => {
            nps.PostAnswer({ ..._answer, question: q.id })
                .catch(console.warn);
        })

        setQuestions([]);
        setQuestionsWhy([]);
        setAnswersWhy([]);
    }

    const handleUndoWhy = () => {

        answersWhy.forEach(a => {
            if (a.id) {
                nps.DeleteAnswer(a.id)
                    .catch(console.warn);
            }
        })

        setQuestionsWhy([]);
        setAnswersWhy([]);
        loadQuestions();
    }

    const handlePostponeWhy = () => {

        questionsWhy.forEach(q => {

            var answer = answersWhy.find(a => a.question == q.id);

            if (answer) {
                answer.reason = '...adiada';
                nps.PutAnswer(answer)
                    .catch(console.warn);
            }
        })

        setQuestions([]);
        setQuestionsWhy([]);
        setAnswersWhy([]);
    }

    return (
        <>
            <SideModal header="Pesquisa" visible={questions.length > 0} onClose={() => setQuestions([])}>
                <form onSubmit={handleSubmitNPS}  >
                    {
                        questions.map((q, i) => {
                            return (
                                <div key={i}>
                                    <LcVote key={i} name={`${q.id}_vote`} min={q.minimum} max={q.maximum} lessText={q.lessText} moreText={q.moreText} question={q.text} />
                                    <hr />
                                </div>
                            )
                        })
                    }
                    <div className="space-between">
                        <button className="lc-button bg-primary" type='submit'>Submeter pesquisa</button>
                        <Confirmation
                            confirm={() => handlePostponeNPS()}
                            text="Você será perguntado novamente depois de algum tempo. Deseja adiar?"
                            textBtnOk="Sim"
                            textBtnCancel="Não" >
                            <button className="lc-button bg-grey" type="button">Adiar</button>
                        </Confirmation>
                    </div>
                </form>
            </SideModal>

            <SideModal header="Pesquisa" visible={questions.length == 0 && answersWhy.length > 0} onClose={() => setAnswersWhy([])}>
                <form onSubmit={handleSubmitAnswersWhy}  >
                    {
                        questionsWhy.map((q, i) => {
                            if (answersWhy.length > 0) {
                                var _answer = answersWhy.find(a => a.question == q.id);
                                if (_answer) {
                                    return (
                                        <div key={i}>
                                            <input type="hidden" name={`${q.id}_vote`} value={_answer.note}></input>
                                            <h5>Na pergunta: <span className="text-link">"{q.text}"</span> você deu a nota <span className="text-link">{_answer.note}</span>. O que te levou a dar essa nota?</h5>
                                            <TextAreaField name={`${q.id}_reason`}
                                                label="Motivo"
                                                rows={5}
                                            />
                                            <hr />
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    <div className="space-between">
                        <button className="lc-button bg-primary" type='submit'>Submeter pesquisa</button>
                        <button className="lc-button bg-secondary" onClick={() => handleUndoWhy()} type="button">Voltar</button>
                        <Confirmation
                            confirm={() => handlePostponeWhy()}
                            text="Você será perguntado novamente depois de algum tempo. Deseja adiar?"
                            textBtnOk="Sim"
                            textBtnCancel="Não" >
                            <button className="lc-button bg-grey" type="button">Adiar</button>
                        </Confirmation>

                    </div>
                </form>
            </SideModal>

            <Alert visible={npsThanksVisible} onClose={setNpsThanksVisible} text="Agradecemos a sua participação na pesquisa de satisfação da Processor. Sua opinião é muito importante para nós." />

        </>
    );
}

export default NPS;