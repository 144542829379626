import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { FinancialManagementService } from '../../services/financialManagement/FinancialManagementService';
import { useHistory } from 'react-router';
import Moment from 'moment';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../store/reducers/filterReducer';

import moment from 'moment';
import Invoices from './Invoices';
import LcLoading from '../../components/Generic/LcLoading';
import LCDashboard from '../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { PiArrowLeftLight, PiFileTextLight } from 'react-icons/pi';

interface filter {
    size: number,
    term: string,
    status: string,
    fabricante: string,
    contractStart: string,
    contractEnd: string,
    column: string,
    direction: string
}

const FinancialManagement: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();
    const filterDefault = { size: 20, term: "", year: new Date().getFullYear().toString(), column: "issuanceDate", direction: "desc" };
    const service = new FinancialManagementService(props);
    const history = useHistory()
    const [contracts, setContracts] = useState<any[]>([]);
    const [contractsMaped, setContractsMaped] = useState<any[]>([]);
    const [filteredContracts, setFilteredContracts] = useState<any[]>([]);
    const [visibleContracts, setVisibleContracts] = useState<any[]>([]);
    const [month, setMonth] = useState<number>(moment().month());
    const [year, setYear] = useState<number>(moment().year());
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState<any[]>([]);
    const [filterVisible, setFilterVisible] = useState(false);

    const [selectedContract, setSelectedContract] = useState<any>();

    const [nfQuantity, setNfQuantity] = useState<number>(0);

    const [itemsQuantity, setItemsQuantity] = useState<number>(0);


    const [filter, setFilter] = useState<filter>({
        size: 20,
        term: "",
        fabricante: "",
        status: "",
        contractStart: "",
        contractEnd: "",
        column: "",
        direction: ""
    });

    // const Fabricantes = [
    //     { value: "", label: 'Todos' },
    //     { value: '57', label: 'Symantec' },
    //     { value: '126', label: 'Processor Max Outsource' },
    //     { value: '147', label: 'Processor Max Sw' },
    //     { value: '170', label: 'LiveWatch' },
    //     { value: '173', label: 'Microsoft EA/EAS/SCE' },
    //     { value: '174', label: 'Processor LiveCloud GTM' },
    //     { value: '176', label: 'Processor CSP' },
    //     { value: '188', label: 'Processor LiveCloud Admin' },
    //     { value: '201', label: 'Processor LiveCloud SaaS' },
    //     { value: '207', label: 'Processor Hey!ho!' },
    //     { value: '208', label: 'Processor Hey!ho!' },
    //     { value: '208', label: 'Processor Procbot' },
    // ];

    const Status = [
        { value: '', label: 'TODOS' },
        { value: 'ATIVO', label: 'ATIVO' },
        { value: 'INATIVO', label: 'INATIVO' },
    ];

    useEffect(() => {
        setIsLoading(true);

        if (!month) {
            setMonth(moment().month());
        }
        if (!year) {
            setYear(moment().year());
        }        

        service.GetClientGroupContractFinancial(month as number, year as number)
            .then((responseContract) => {                
                service.GetClients()
                    .then(response => {

                        let result: any[] = [];
                        responseContract.map((item: any) => {
                            if (item.contractStatus == 'ATIVO') {
                                result.push(item);
                                return true;
                            }
                        });
                        let _responseOrderedActive: any[] = []
                        responseContract.forEach((item: any) => {
                            if (item.contractStatus == 'ATIVO')
                                _responseOrderedActive.push(item);
                        });

                        let _contractSortedActive = _responseOrderedActive.sort((a: any, b: any) => {
                            return (a['contractEnd'] > b['contractEnd']) ? 1 : -1;
                        });

                        let _responseOrderedInactive: any[] = []
                        responseContract.forEach((item: any) => {
                            if (item.contractStatus != 'ATIVO')
                                _responseOrderedInactive.push(item)
                        });

                        let _contractSortedInactive = _responseOrderedInactive.sort((a: any, b: any) => {
                            return (a['contractEnd'] > b['contractEnd']) ? 1 : -1;
                        });

                        _contractSortedActive = _contractSortedActive.concat(_contractSortedInactive)

                        setContracts(_contractSortedActive);

                        const client = response.data[0];
                        var contractsMap = result.map((item: any) => {
                            return (
                                item.clientId == 0 ? { ...item, clientId: client.id } : item
                            )

                        });
                        setContractsMaped([]);                        
                        setContractsMaped(_contractSortedActive);                        
                        setFilteredContracts([]);
                        setFilteredContracts(_contractSortedActive);                        
                        setVisibleContracts(_contractSortedActive.slice(0, 20));
                        setClients(response.data);
                        setIsLoading(false);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {


                console.log(error);
            });

    }, [user.ClientGroupSelected, user.refreshFlag, month, year]);


    const handleFilter = (_filters, size) => {
        let isFilterActive = false;

        // Encontra cada filtro pelo nome e obtém o valor com segurança.
        const termFilter = _filters.find(f => f.name === 'search');
        const fabricanteFilter = _filters.find(f => f.name === 'Fabricante');
        const statusFilter = _filters.find(f => f.name === 'Status');
        const contractStartFilter = _filters.find(f => f.name === 'contractStart');
        const contractEndFilter = _filters.find(f => f.name === 'contractEnd');

        // Usa optional chaining (?.) para acessar .value e evita erros se o filtro não existir.
        const term = termFilter?.value?.toLowerCase() ?? '';
        const fabricante = fabricanteFilter?.value?.toLowerCase() ?? '';
        const status = statusFilter?.value ?? '';
        const contractStart = contractStartFilter?.value ? new Date(contractStartFilter.value) : null;
        const contractEnd = contractEndFilter?.value ? new Date(contractEndFilter.value) : null;

        let filtered = contractsMaped.filter(contract => {
            let match = true;

            // Aplica filtro de termo se definido
            if (term) {
                match = match && contract.contractName.toLowerCase().includes(term);
            }

            // Aplica filtro de fabricante se definido
            if (fabricante) {
                match = match && contract.vendorDescription.toLowerCase().includes(fabricante);
            }

            // Aplica filtro de status se definido
            if (status) {
                match = match && contract.contractStatus === status;
            }

            // Aplica filtro de data de início se definido
            if (contractStart) {
                const contractStartDate = new Date(contract.contractStart);
                match = match && contractStartDate >= contractStart;
            }

            // Aplica filtro de data de fim se definido
            if (contractEnd) {
                const contractEndDate = new Date(contract.contractEnd);
                match = match && contractEndDate <= contractEnd;
            }

            return match;
        });

        isFilterActive = filtered.length !== contractsMaped.length;
        dispatch(setFilterActive(isFilterActive));

        setFilteredContracts(filtered);
        setVisibleContracts(filtered.slice(0, size));
    };



    const handleFilterChangeWrapper = (updatedFilters) => {

        const size = 20; // Defina o tamanho conforme necessário, ou ajuste sua função para não precisar dele.

        // Chama a função handleFilter com os argumentos necessários
        handleFilter(updatedFilters, size);
    };


    useEffect(() => {
        let filtered = contractsMaped.filter((p: any) => (
            p.contractId?.toString().toLowerCase().indexOf(filter.term) > -1 ||
            p.contractName.toLowerCase().indexOf(filter.term) > -1
        ));

        filtered = filtered.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredContracts(filtered);
        setVisibleContracts(filtered);

    }, [filter, filter.column, filter.direction])

    const filterAdvanced = {
        fields: [
            { label: 'Contrato', name: 'search', type: 'text' },
            { label: 'Fabricante', name: 'Fabricante', type: 'text' },
            { label: 'Situação', name: 'Status', type: 'select', options: Status },
            { label: 'Início', name: 'contractStart', type: 'date' },
            { label: 'Renovação', name: 'contractEnd', type: 'date' },
        ],
        onChange: (_filter: any, size: number) => {
            handleFilter(_filter, size)
            //const fabricante = Fabricantes.filter(x => x.value == filter[1].value)[0]
            let filterCreated = {
                size: size + 0.5 || filter.size,
                term: _filter[0].value.toLowerCase() || '',
                fabricante: _filter[1].value.toLowerCase() || '',
                status: _filter[2].value.toLowerCase() || '',
                contractStart: _filter[3].value || '',
                contractEnd: _filter[4].value || '',
                column: filter.column,
                direction: filter.direction
            }
            setFilter(filterCreated)
            setFilterVisible(false)
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const columnsTable = [
        { field: 'contractId', headerName: 'Contrato', width: "10%" },
        {
            field: 'vendorDescription', headerName: 'Fabricante', width: "60%",
            renderCell: (row: any) => {

                if (row.friendlyName != null && row.friendlyName != "") return row.vendorDescription + " - " + row.friendlyName;
                else return row.vendorDescription;
            }
        },
        {
            field: 'contractStart', headerName: 'Início', width: "15%",
            renderCell: (row: any) => {
                return <div className="date">{new Date(row.contractStart).toLocaleDateString('pt-BR')}</div>
            }
        },
        // {
        //     field: 'contractEnd', headerName: 'Renovação', width: "10%", align: 'left',
        //     renderCell: (row: any) => {
        //         return <div className="date">{new Date(row.contractEnd).toLocaleDateString('pt-BR')}</div>
        //     }
        // },
        { field: 'contractStatus', headerName: 'Status', width: "10%" },
        {
            field:'',
            headerName: 'NF', width: "32px", sort: false, align: "center", overflow: "visible",
            renderCell: (row: any) => {
                return <LcIconLink icon={<PiFileTextLight />} size="small" tooltip="Ver notas fiscais"
                    onClick={() => {
                        setSelectedContract(row);
                        // const UrlLocation = `/FinancialManagementInvoices/${row.clientId}/${row.contractId}`
                        // history.push(UrlLocation)
                    }} />
            }
        }
    ];

    const onChagePeriod = (selected: any) => {
        var dateMoment = moment(selected.fields[0].value);
        setMonth(dateMoment.month());
        setYear(dateMoment.year());
    }

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const getStatus = (row: any) => {
        return row.contractStatus == "ATIVO" ? "success" : "danger";
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleContracts(filteredContracts.slice(0, size))
        else setVisibleContracts([...visibleContracts, ...filteredContracts.slice(visibleContracts.length, visibleContracts.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const functionsGeneric = [
        {
            title: 'Voltar',
            icon: <PiArrowLeftLight />,
            tooltip: "Voltar",
            onClick: () => {
                setSelectedContract(undefined)
            }
        }
    ]

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoading} loadingType='Helix'>
                        {
                            !selectedContract ?
                                <LcInfiniteTable
                                    status={getStatus}
                                    loading={isLoading}
                                    columns={columnsTable}
                                    rows={visibleContracts}
                                    filter={filterAdvanced}
                                    size={filteredContracts.length}
                                    loadMore={loadMore}
                                    onSortChange={onSortChange}
                                    density={pref.data?.density || "high"}
                                    disableFilterModal
                                    tooltipContentColumn='contractStatus'
                                />
                                :
                                <Invoices
                                    contract={selectedContract}
                                    nfQuantity={(qtd) => { setNfQuantity(qtd) }}
                                    visibleFilter={filterVisible}
                                    closeFilter={setFilterVisible}
                                />
                        }
                    </LcLoading>
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChangeWrapper}
            />
        );
    };

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    return (
        <Layout pageTitle="Meus contratos"
            //periodSelection={periodSelection}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: selectedContract ? nfQuantity : filteredContracts.length
            }}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            functionsGeneric={selectedContract && functionsGeneric}
            loading={isLoading}
        > 
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />

        </Layout>
    );
}

export default FinancialManagement;